// Packages
import React from "react";
import {
  IonPage,
  IonSpinner,
  IonButton,
  IonPopover,
  IonList,
  IonIcon,
} from "@ionic/react";
import { Link } from "react-router-dom";
import { observable, autorun, action } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import moment from "moment";
import {
  CalendarOutlined,
  DeleteOutlined,
  EyeOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
// Components
import { Empty, Popconfirm, List, Avatar, Tag, message } from "antd";
// Styles
import "./CheckIns.scss";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";
import { IActivity, ICheckIn } from "../../shared/interfaces";
import ActiveReviewNReflectSVG from "../../components/svgComponents/ActiveReviewNReflectSVG";
import CalenderSVG from "../../components/svgComponents/CalenderSVG";
import DeleteSVG from "../../components/svgComponents/DeleteSVG";
import EditSVG from "../../components/svgComponents/EditSVG";
import { ellipsisVertical } from "ionicons/icons";
import { CrossSVG } from "../../components/svgComponents/CrossSVG";

interface IProps extends RouteComponentProps {
  checkInStore: any;
}

@inject("checkInStore")
@observer
class CheckIns extends React.Component<IProps> {
  @observable
  public loading = false;
  @observable public selectedItems: any[] = [];
  @observable public isSelectItemsOn: boolean = false;
  @disposeOnUnmount
  public checkInsAutorun = autorun(() => {
    this.getCheckIns();
  });

  @action.bound
  public getCheckIns = () => {
    if (this.props.checkInStore.checkIns.length < 1) this.loading = true;
    this.props.checkInStore.getCheckIns().then((checkIns: ICheckIn[]) => {
      this.loading = false;
    });
  };

  @action.bound
  public archiveCheckIn = (checkIn: ICheckIn) => {
    this.props.checkInStore.updateCheckIn(
      checkIn.id,
      checkIn.userId,
      undefined,
      undefined,
      undefined,
      undefined,
      (checkIn.deleted = 1)
    );
    message.success("Check-in deleted");
  };

  @action.bound
  public archiveCheckIns = (selectedItems: ICheckIn[]) => {
    selectedItems.map((checkIn: ICheckIn) => {
      return this.props.checkInStore.updateCheckIn(
        checkIn.id,
        checkIn.userId,
        undefined,
        undefined,
        undefined,
        undefined,
        (checkIn.deleted = 1)
      );
    });
    message.success("Check-ins deleted");
    this.isSelectItemsOn = false;
    this.selectedItems = [];
    this.getCheckIns();
  };

  @action.bound
  public handleSelectedItems = (checkIn: ICheckIn) => {
    let alreadySelected = this.selectedItems.find(
      (item) => item.id === checkIn.id
    );
    if (alreadySelected) {
      this.selectedItems = this.selectedItems.filter(
        (item) => item.id !== checkIn.id
      );
    } else {
      this.selectedItems.push(checkIn);
    }
  };

  @action.bound
  public handleMultiMenuOpen = () => {
    this.isSelectItemsOn = true;
  };

  public renderCheckInAction(checkIn: ICheckIn) {
    if (checkIn.status === "complete") {
      return <button className="checkIn">View</button>;
    } else if (checkIn.status === "step-1") {
      return <button className="checkIn">Check in</button>;
    } else {
      return <button className="checkIn">Resume</button>;
    }
  }

  public renderCheckInStatus(checkIn: ICheckIn) {
    if (checkIn.status === "complete") {
      return (
        <Tag className="checkInTag" color="green">
          Completed
        </Tag>
      );
    } else if (checkIn.status === "step-1") {
      return (
        <Tag className="checkInTag" color="red">
          Due
        </Tag>
      );
    } else {
      return (
        <Tag className="checkInTag inProgress" color="yellow">
          In progress
        </Tag>
      );
    }
  }

  public renderCheckIns = () => {
    if (!this.loading && this.props.checkInStore.checkIns.length < 1) {
      return (
        <Empty
          description={
            <>
              <span>Check back on Sunday for your first one!</span>
            </>
          }
        />
      );
    } else if (!this.loading) {
      return (
        <div>
          <div className="card-links">
            <h2 className="title-check-ins">CHECK INS</h2>
            {this.isSelectItemsOn ? (
              <div className="flexBoxRow">
                <IonButton
                  color="danger"
                  onClick={() => this.archiveCheckIns(this.selectedItems)}
                >
                  Delete
                </IonButton>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="closeSVG"
                  onClick={() => {
                    this.isSelectItemsOn = false;
                  }}
                >
                  <CrossSVG />
                </div>
              </div>
            ) : (
              <KebabMenu
                deleteGoals={this.handleMultiMenuOpen}
                handleMultiMenuOpen={this.handleMultiMenuOpen}
              />
            )}
          </div>
          <div className="list-container">
            <List
              itemLayout="horizontal"
              dataSource={this.props.checkInStore.checkIns}
              renderItem={(checkIn: ICheckIn, index) => (
                <List.Item
                  key={index}
                  actions={[
                    <div className="flexBoxRow">
                      {this.renderCheckInStatus(checkIn)}
                      <Link
                        to={`/reviews-reflect/${checkIn.id}`}
                        key="list-action"
                      >
                        {this.renderCheckInAction(checkIn)}
                      </Link>
                    </div>,
                  ]}
                >
                  <List.Item.Meta
                    className="list-item"
                    avatar={
                      <div className="filledCalender">
                        {this.isSelectItemsOn && (
                          <input
                            className="check-in-checkbox"
                            key={index}
                            defaultChecked={
                              this.selectedItems.find(
                                (item) => item.id === checkIn.id
                              )
                                ? true
                                : false
                            }
                            type="checkbox"
                            onChange={() => this.handleSelectedItems(checkIn)}
                          />
                        )}
                        <CalenderSVG color="#FF7E1D" width={18} height={20} />
                      </div>
                    }
                    title={
                      <div className="flexBoxRow" style={{ gap: "0.2rem" }}>
                        <h2 className="title-check-ins">
                          {moment(checkIn.endDate).format("MMMM Do")}
                          &nbsp; Check-in
                        </h2>
                        <Popconfirm
                          title="Are you sure you want to delete this Review & Reflect?"
                          onConfirm={() => this.archiveCheckIn(checkIn)}
                          okText="Delete Review-Reflect"
                          cancelText="Keep it"
                        >
                          <div className="check-ins-row-icons">
                            <DeleteSVG color="#413D45" />
                          </div>
                        </Popconfirm>
                      </div>
                    }
                    description={
                      <div>
                        For {moment(checkIn.startDate).format("MMM Do")} -{" "}
                        {moment(checkIn.endDate).format("Do, YYYY")}
                      </div>
                    }
                  />

                  {/* <List.Item.Meta
                avatar={
                  <Avatar className="goalden-avatar">
                    {moment(checkIn.endDate).format("MM/DD")}
                  </Avatar>
                }
                title={
                  <>
                    <CalendarOutlined />
                    &nbsp; Week ending{" "}
                    {moment(checkIn.endDate).format("dddd, MMMM Do YYYY")}
                    <Popconfirm
                      title="Are you sure you want to delete this Review & Reflect?"
                      onConfirm={() => this.archiveCheckIn(checkIn)}
                      okText="Delete Review-Reflect"
                      cancelText="Keep it"
                    >
                      <DeleteOutlined className="check-ins-row-icons" />
                    </Popconfirm>
                  </>
                }
                description={this.renderCheckInStatus(checkIn)}
                /> */}
                </List.Item>
              )}
            />
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <IonPage className="scroll-page ion-padding">
        <div className="main-content">
          <div className="header">
            <h1 className="SiteHeader">
              <div className="IconWrapper">
                <ActiveReviewNReflectSVG />
              </div>
              Review & reflect
            </h1>
          </div>

          {this.loading ? <IonSpinner className="loading" /> : null}

          {this.renderCheckIns()}
        </div>
      </IonPage>
    );
  }
}

export default CheckIns;

interface KebabMenuProps {
  deleteGoals: any;
  handleMultiMenuOpen: () => void;
}

interface KebabMenuState {
  showPopover: boolean;
  event?: Event;
}

class KebabMenu extends React.Component<KebabMenuProps, KebabMenuState> {
  constructor(props: KebabMenuProps) {
    super(props);
    this.state = {
      showPopover: false,
    };
  }

  handlePopoverClose = () => {
    this.setState({ showPopover: false });
  };

  handlePopoverOpen = (e: React.MouseEvent) => {
    this.setState({ showPopover: true, event: e.nativeEvent });
  };

  handleSelectMenuOpen = () => {
    this.props.handleMultiMenuOpen();
  };

  // handleDeselectItem = (itemId: string) => {
  //   this.setState(prevState => ({
  //     selectedItems: prevState.selectedItems.filter(id => id !== itemId)
  //   }));
  // };

  // handleDeleteSelectedItems = () => {
  //   const { selectedItems } = this.state;
  //   // Here you would call your method to delete the selected items
  //   // For example:
  //   this.props.deleteGoals(selectedItems);
  //   // Then clear the selection
  //   this.setState({ selectedItems: [] });
  // };

  render() {
    return (
      <>
        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.event}
          onDidDismiss={this.handlePopoverClose}
        >
          <IonList className="small-width">
            <IonButton
              onClick={() => this.handleSelectMenuOpen()}
              expand="full"
              className="no-background"
            >
              <div className="edit">
                <EditSVG /> Select multiple
              </div>
            </IonButton>
            {/* <IonButton expand="full" className="no-background">
              <div className="edit">
                {this.checkmarkIcon()}
                Complete
              </div>
            </IonButton> */}
            {/* <IonButton expand="full" className="no-background">
              <Popconfirm
                title="Are you sure you want to delete this goal?"
                onConfirm={this.handleResetGoalActivitiesClick}
                okText="Delete goal"
                cancelText="Keep at it"
              >
                <div className="delete">
                  <DeleteSVG />
                  Delete
                </div>
              </Popconfirm>
            </IonButton> */}
          </IonList>
        </IonPopover>
        <IonButton
          className="menu-button"
          onClick={(e) => this.handlePopoverOpen(e)}
        >
          <IonIcon icon={ellipsisVertical} />
        </IonButton>
      </>
    );
  }
}
