import React from "react";

type IconSVGProps = {
  color?: string;
};

const IconSVG: React.FC<IconSVGProps> = ({ color }) => {
  return (
    <svg
      width="126"
      height="10"
      viewBox="0 0 126 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.73093 10C1.87673 10 0 7.8453 0 5.02762V5C0 2.29282 1.95493 0 4.70486 0C6.07331 0 6.98561 0.345304 7.81972 0.980663C7.97611 1.10497 8.14554 1.33978 8.14554 1.67127C8.14554 2.12707 7.79365 2.51381 7.35054 2.51381C7.14201 2.51381 6.98562 2.41713 6.85529 2.32044C6.26881 1.86464 5.6302 1.56077 4.6397 1.56077C2.95846 1.56077 1.68124 3.12155 1.68124 4.97238V5C1.68124 6.98895 2.91936 8.45304 4.78306 8.45304C5.64323 8.45304 6.4252 8.16298 6.98561 7.721V5.9116H5.18708C4.79609 5.9116 4.47027 5.59392 4.47027 5.17956C4.47027 4.76519 4.79609 4.4337 5.18708 4.4337H7.72849C8.18464 4.4337 8.53653 4.80663 8.53653 5.29006V7.87293C8.53653 8.35635 8.35407 8.70166 7.97611 8.95028C7.19414 9.50276 6.09938 10 4.73093 10Z"
        fill={color ? color : "#2B2930"}
      />
      <path
        d="M16.2804 10C13.4783 10 11.5234 7.74862 11.5234 5.02762V5C11.5234 2.27901 13.5044 0 16.3065 0C19.1085 0 21.0634 2.25138 21.0634 4.97238V5C21.0634 7.721 19.0825 10 16.2804 10ZM16.3065 8.42542C18.118 8.42542 19.3822 6.90608 19.3822 5.02762V5C19.3822 3.12155 18.092 1.56077 16.2804 1.56077C14.4688 1.56077 13.2046 3.09392 13.2046 4.97238V5C13.2046 6.87845 14.4949 8.42542 16.3065 8.42542Z"
        fill={color ? color : "#2B2930"}
      />
      <path
        d="M23.2834 9.11602C23.2834 8.99171 23.3225 8.8674 23.3877 8.72928L26.7762 0.718232C26.9587 0.290055 27.2845 0.027624 27.7407 0.027624H27.8188C28.275 0.027624 28.5878 0.290055 28.7703 0.718232L32.1588 8.72928C32.224 8.85359 32.25 8.9779 32.25 9.0884C32.25 9.5442 31.9242 9.90332 31.4941 9.90332C31.1162 9.90332 30.8555 9.66851 30.7121 9.30939L29.9693 7.5H25.5381L24.7692 9.36464C24.6388 9.70994 24.3651 9.90332 24.0263 9.90332C23.6092 9.90332 23.2834 9.55801 23.2834 9.11602ZM26.1506 5.99448H29.3567L27.7537 2.08563L26.1506 5.99448Z"
        fill={color ? color : "#2B2930"}
      />
      <path
        d="M35.2924 8.9779V0.939226C35.2924 0.469613 35.6443 0.0966847 36.1004 0.0966847C36.5435 0.0966847 36.8954 0.469613 36.8954 0.939226V8.28729H41.0268C41.4309 8.28729 41.7567 8.6326 41.7567 9.06077C41.7567 9.48895 41.4309 9.83425 41.0268 9.83425H36.1004C35.6443 9.83425 35.2924 9.46133 35.2924 8.9779Z"
        fill={color ? color : "#2B2930"}
      />
      <path
        d="M44.6648 8.9779V1.0221C44.6648 0.538674 45.0167 0.165745 45.4729 0.165745H48.0664C50.9336 0.165745 52.9146 2.25138 52.9146 4.97238V5C52.9146 7.721 50.9336 9.83425 48.0664 9.83425H45.4729C45.0167 9.83425 44.6648 9.46133 44.6648 8.9779ZM46.2679 8.28729H48.0664C49.9823 8.28729 51.2334 6.91989 51.2334 5.02762V5C51.2334 3.10774 49.9823 1.71271 48.0664 1.71271H46.2679V8.28729Z"
        fill={color ? color : "#2B2930"}
      />
      <path
        d="M56.9708 9.83425C56.5147 9.83425 56.1628 9.46133 56.1628 8.9779V1.0221C56.1628 0.538674 56.5147 0.165745 56.9708 0.165745H62.2752C62.6662 0.165745 62.992 0.51105 62.992 0.925414C62.992 1.35359 62.6662 1.68508 62.2752 1.68508H57.7658V4.19889H61.6887C62.0797 4.19889 62.4055 4.5442 62.4055 4.97238C62.4055 5.38674 62.0797 5.71823 61.6887 5.71823H57.7658V8.31492H62.3404C62.7313 8.31492 63.0572 8.66022 63.0572 9.07459C63.0572 9.50276 62.7313 9.83425 62.3404 9.83425H56.9708Z"
        fill={color ? color : "#2B2930"}
      />
      <path
        d="M66.1971 9.07459V0.966851C66.1971 0.497237 66.549 0.110497 67.0051 0.110497H67.1745C67.5655 0.110497 67.7871 0.31768 68.0086 0.607735L72.5701 6.86464V0.925414C72.5701 0.469613 72.922 0.0966847 73.3521 0.0966847C73.7952 0.0966847 74.1471 0.469613 74.1471 0.925414V9.04696C74.1471 9.51658 73.8083 9.8895 73.3651 9.8895H73.3C72.922 9.8895 72.6874 9.68232 72.4659 9.37845L67.774 2.94199V9.07459C67.774 9.53039 67.4221 9.90332 66.9921 9.90332C66.549 9.90332 66.1971 9.53039 66.1971 9.07459Z"
        fill={color ? color : "#2B2930"}
      />
      <path
        d="M83.7968 9.06077V0.939226C83.7968 0.469613 84.1487 0.0966847 84.6049 0.0966847C85.048 0.0966847 85.3999 0.469613 85.3999 0.939226V4.18508H89.7529V0.939226C89.7529 0.469613 90.1047 0.0966847 90.5479 0.0966847C91.004 0.0966847 91.3559 0.469613 91.3559 0.939226V9.06077C91.3559 9.53039 91.004 9.90332 90.5479 9.90332C90.1047 9.90332 89.7529 9.53039 89.7529 9.06077V5.75967H85.3999V9.06077C85.3999 9.53039 85.048 9.90332 84.6049 9.90332C84.1487 9.90332 83.7968 9.53039 83.7968 9.06077Z"
        fill={color ? color : "#2B2930"}
      />
      <path
        d="M99.355 10C96.5529 10 94.598 7.74862 94.598 5.02762V5C94.598 2.27901 96.579 0 99.3811 0C102.183 0 104.138 2.25138 104.138 4.97238V5C104.138 7.721 102.157 10 99.355 10ZM99.3811 8.42542C101.193 8.42542 102.457 6.90608 102.457 5.02762V5C102.457 3.12155 101.167 1.56077 99.355 1.56077C97.5434 1.56077 96.2793 3.09392 96.2793 4.97238V5C96.2793 6.87845 97.5695 8.42542 99.3811 8.42542Z"
        fill={color ? color : "#2B2930"}
      />
      <path
        d="M111.119 9.98619C108.747 9.98619 107.235 8.53591 107.235 5.73204V0.939226C107.235 0.469613 107.587 0.0966847 108.043 0.0966847C108.486 0.0966847 108.838 0.469613 108.838 0.939226V5.66298C108.838 7.45856 109.711 8.4116 111.145 8.4116C112.565 8.4116 113.438 7.51381 113.438 5.73204V0.939226C113.438 0.469613 113.79 0.0966847 114.246 0.0966847C114.69 0.0966847 115.041 0.469613 115.041 0.939226V5.64917C115.041 8.53591 113.504 9.98619 111.119 9.98619Z"
        fill={color ? color : "#2B2930"}
      />
      <path
        d="M118.545 9.06077V1.0221C118.545 0.538674 118.897 0.165745 119.353 0.165745H122.611C123.758 0.165745 124.658 0.524862 125.244 1.1326C125.726 1.65746 126 2.37569 126 3.21823V3.24586C126 4.79282 125.153 5.73204 123.928 6.1326L125.674 8.46685C125.831 8.67403 125.935 8.85359 125.935 9.11602C125.935 9.58564 125.557 9.90332 125.166 9.90332C124.801 9.90332 124.566 9.72376 124.384 9.46133L122.168 6.46409H120.148V9.06077C120.148 9.53039 119.796 9.90332 119.353 9.90332C118.897 9.90332 118.545 9.53039 118.545 9.06077ZM120.148 4.95856H122.494C123.641 4.95856 124.371 4.3232 124.371 3.34254V3.31492C124.371 2.27901 123.667 1.71271 122.481 1.71271H120.148V4.95856Z"
        fill={color ? color : "#2B2930"}
      />
    </svg>
  );
};

export default IconSVG;
