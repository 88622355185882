// Packages
import React from "react";
import { observable, computed, action, autorun } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import { track } from "../../../plugins/analytics";
import { LoadingOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
// Styles
import { Input, Button, Alert, message, Form, DatePicker, Select } from "antd";
// Type Definitions
import { IEditGoalForm } from "../../interfaces";
import moment from "moment";
import { FilledStarSVG } from "../../../components/svgComponents/FilledStarSVG";
import { NotFilledStarSVG } from "../../../components/svgComponents/NotFilledStarSVG";

interface IProps {
  goalStore?: any;
  closeGoalModal: () => void;
}

@inject("goalStore")
@observer
class EditGoalForm extends React.Component<IProps> {
  @observable public loading = false;
  @observable public error = null;
  @observable public editedGoal: IEditGoalForm = {
    name: "",
    description: "",
    completedOn: "",
    isActive: true,
    isPriority: false,
    status: "incomplete",
  };

  @observable public action = "";

  @computed get goal() {
    return { ...this.props.goalStore.activeGoal };
  }

  @disposeOnUnmount
  public userTokenReaction = autorun(() => {
    if (this.props.goalStore.updateGoalModal) {
      this.editedGoal = this.goal;
      this.editedGoal.isActive = this.goal.isActive === 1 || this.goal.isActive === true ? true : false;
      this.editedGoal.isPriority = this.goal.isPriority === 1 || this.goal.isPriority === true ? true : false;
      this.goal.isActive = this.goal.isActive === 1 || this.goal.isActive === true ? true : false
      this.goal.isPriority = this.goal.isPriority === 1 || this.goal.isPriority === true ? true : false;
      console.log("editedGoal", this.editedGoal);
    }
  });

  @action.bound
  public handleSubmit = async (event: React.FormEvent) => {
    //event.preventDefault();
    this.loading = true;
    track("Goal Edited");
    if(this.action === ""){
     this.action = this.editedGoal.status === "complete"
        ? "sendToCompleteFromComplete"
        : this.editedGoal.isPriority
        ? "sendToPriorityFromPriority"
        : this.editedGoal.isActive
        ? "sendToActiveFromActive"
        : "sendToInactiveFromInactive";
    }

    const resError = await this.props.goalStore.updateGoal(
      this.editedGoal,
      this.editedGoal.isActive ? 1 : 0,
      this.editedGoal.isPriority ? 1 : 0,
      this.action
    );
    this.error = resError;
    this.loading = false;
    if (!this.error) {
      this.props.goalStore.updateGoalModal = false;
      message.success("Goal updated!");
    }
  };

  @action.bound
  public handleChange = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.FormEvent<HTMLTextAreaElement>
  ) => {
    const target = event.currentTarget;
    switch (target.name) {
      case "name":
        this.editedGoal.name = target.value;
        break;
      case "description":
        this.editedGoal.description = target.value;
        break;
      // case "completedOn":
      //   this.editedGoal.completedOn = target.value;
      //   break;
      default:
        console.log("Unknown form value change");
    }
  };

  @action.bound
  public handleDateChange = (value: any) => {
    this.editedGoal.completedOn = value;
  };

  public isSubmitButtonDisabled = () => {
    return this.editedGoal.name === undefined || this.editedGoal.name === "";
  };

  public renderSubmitBtn = () => {
    if (this.loading) {
      return (
        <Button
          type="primary"
          htmlType="submit"
          className="form-submit-button"
          disabled
        >
          <LoadingOutlined />
        </Button>
      );
    } else {
      return (
        <Button
          type="primary"
          htmlType="submit"
          className="form-submit-button"
          disabled={this.isSubmitButtonDisabled()}
        >
          Update
        </Button>
      );
    }
  };
  @action.bound
  public handlePriorityClick = () => {
    console.log("priority click")
    if (this.editedGoal.isActive) {
      this.editedGoal.isPriority = !this.editedGoal.isPriority;
      if(this.editedGoal.isPriority){
        message.success("Goal set as priority.");
        this.action = "sendToPriority";
        this.action += this.goal.isActive === false ? "FromInactive" : this.goal.status === "complete" ? "FromComplete" : "";
      } else {
        message.success("Goal removed from priority.");
        this.action = "sendToActiveFromPriority";
      }
    } else {
      message.warning("Inactive goals cannot be set as priority.");
    }
  };

  render() {
    const { TextArea } = Input;
    const { activeGoal } = this.props.goalStore;

    const disabledDate = (current: moment.Moment) => {
      // Disable dates after completedOn (if set)
      const completedOn = moment(this.editedGoal.completedOn, "YYYY-MM-DD");
      return (
        current && completedOn.isValid() && current.isAfter(completedOn, "day")
      );
    };

    return (
      <>
        <div className="goal-form-wrapper">
         <div className="error-container">
          {this.error ? (
            <Alert message={this.error} type="error" showIcon />
          ) : (
            ""
          )}
          </div>
          <h3 className="montX XL">Edit Goal</h3>

          <Form onFinish={this.handleSubmit} className="goal-form">
            <Form.Item>
              <label className="label" htmlFor="name">
                Name*
              </label>
              <Input
                name="name"
                placeholder="Name (required)"
                value={this.editedGoal.name}
                onChange={this.handleChange}
                required
              />
            </Form.Item>

            <Form.Item>
              <label className="label" htmlFor="description">
                Description
              </label>
              <TextArea
                style={{ minHeight: "90px", resize: "none" }}
                name="description"
                placeholder="Description"
                value={this.editedGoal.description}
                onChange={this.handleChange}
              />
            </Form.Item>

            {activeGoal.status === "complete" && (
              <Form.Item
                label={<p className="mont M formLabel">Completed On</p>}
              >
                <DatePicker
                  defaultValue={moment(
                    this.editedGoal.completedOn,
                    "YYYY-MM-DD hh:mm:ss"
                  )}
                  id="completedOn"
                  name="completedOn"
                  placeholder="Completed On"
                  value={moment(
                    this.editedGoal.completedOn,
                    "YYYY-MM-DD hh:mm:ss"
                  )}
                  disabledDate={disabledDate}
                  onChange={(value: any) => this.handleDateChange(value)}
                />
              </Form.Item>
            )}
            <Form.Item>
              <div className="flexBoxRow">
                <Select
                  defaultValue="active"
                  value={
                    this.editedGoal.status === "complete"
                      ? "completed"
                      : this.editedGoal.isActive === true
                      ? "active"
                      : "inactive"
                  }
                  className={
                    this.editedGoal.status === "complete"
                      ? "status-completed"
                      : this.editedGoal.isActive === true
                      ? "status-active"
                      : "status-inactive"
                  }
                  onChange={(value) => {
                    switch (value) {
                      case "active":
                        this.action = "sendToActive";
                        this.action +=
                          this.goal.isActive === false
                            ? "FromInactive"
                            : this.goal.status === "complete"
                            ? "FromComplete"
                            : "";
                        this.editedGoal.isActive = true;
                        this.editedGoal.status = "incomplete";
                        break;
                      case "inactive":
                        this.action = "sendToInactive";
                        this.action += this.goal.isPriority
                          ? "FromPriority"
                          : this.goal.status === "complete"
                          ? "FromComplete"
                          : this.goal.isActive === true && "FromActive";
                        this.editedGoal.isActive = false;
                        this.editedGoal.isPriority &&
                          message.warning(
                            "Inactive goals cannot be set as priority."
                          );
                        this.editedGoal.isPriority = false;
                        this.editedGoal.status = "incomplete";

                        break;
                      case "completed":
                        this.editedGoal.isPriority = false;
                        this.editedGoal.status = "complete";
                        message.success("Congratulations! Goal completed!");
                        this.action = "sendToCompleted";
                        this.action +=
                          this.goal.isActive === false
                            ? "FromInactive"
                            : this.goal.isPriority
                            ? "FromPriority"
                            : "FromActive";
                        break;
                      default:
                        this.goal.isActive = true;
                        break;
                    }
                    // const isActive =
                    //   value === "active"
                    //     ? true
                    //     : value === "inactive"
                    //     ? false
                    //     : false;
                    // const isCompleted = value === "completed" ? true : false;
                    // this.editedGoal.isActive = isActive;
                    // this.editedGoal.completedOn = isCompleted ? moment().format("YYYY-MM-DD") : "";
                  }}
                >
                  <Select.Option value="active">Active</Select.Option>
                  <Select.Option value="inactive">Inactive</Select.Option>
                  <Select.Option value="completed">Completed</Select.Option>
                </Select>
                {this.editedGoal.status === "incomplete" && (
                  <button
                    type="button"
                    onClick={() => {
                      this.handlePriorityClick();
                    }}
                    className={`priority-btn ${
                      !this.editedGoal.isActive
                        ? "priority-btn-inactive"
                        : this.editedGoal.isPriority && this.editedGoal.isActive
                        ? "active-priority"
                        : "priority-btn-active"
                    }`}
                  >
                    {this.editedGoal.isPriority ? (
                      <div className="priority-text">
                        <FilledStarSVG color={"#ffc773"} />
                        Priority
                      </div>
                    ) : (
                      <div className="priority-text">
                        {!this.editedGoal.isActive ? (
                          <NotFilledStarSVG color="#939597" />
                        ) : (
                          <NotFilledStarSVG color="black" />
                        )}
                        Priority
                      </div>
                    )}
                  </button>
                )}
              </div>
            </Form.Item>
            <div className="form-buttons-wrapper">
              <Button
                className="cancel-button"
                onClick={this.props.closeGoalModal}
              >
                Cancel
              </Button>
              {this.renderSubmitBtn()}
            </div>
          </Form>
        </div>
      </>
    );
  }
}

export default EditGoalForm;
