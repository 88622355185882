// Packages
import React from "react";
import { IonPage, IonSpinner } from "@ionic/react";
import { withRouter, Link } from "react-router-dom";
import { observable, autorun, action, computed } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import moment from "moment";
import { CalendarOutlined, FileDoneOutlined } from "@ant-design/icons";
// Components
import { Empty, Breadcrumb, Steps, Alert, Input, Radio } from "antd";
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import StepThree from "./StepThree/StepThree";
import StepsComplete from "./StepsComplete/StepsComplete";
// Styles
import "./CheckIn.scss";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";
import { ICheckIn } from "../../shared/interfaces";
import ActiveReviewNReflectSVG from "../../components/svgComponents/ActiveReviewNReflectSVG";
import RightArrowSVG from "../../components/svgComponents/RightArrowSVG";
import { StepProps } from "antd/lib/steps";
interface IProps extends RouteComponentProps {
  checkInStore?: any;
  activityStore?: any;
  match: any;
}

const { Step } = Steps;

@inject("checkInStore", "activityStore")
@observer
class CheckIn extends React.Component<IProps> {
  @observable
  public loading = false;
  @observable public skipToCheckIn = false;

  @computed get checkIn() {
    return this.props.checkInStore.activeCheckIn;
  }

  @computed get checkInId() {
    return this.props.match.params.id;
  }

  @computed get stepStatus() {
    // console.log(Number(this.checkIn.status.split("-")[1]) - 1);
    if (this.checkIn.status === "complete") {
      return 3; // Mark all steps complete for antd
    } else {
      return Number(this.checkIn.status.split("-")[1]) - 1;
    }
  }

  public componentWillUnmount() {
    this.props.activityStore.tasks = [];
    this.props.activityStore.routines = [];
  }

  @disposeOnUnmount
  public checkInAutorun = autorun(() => {
    console.log("auto run");
    this.getCheckIn(this.checkInId);
  });

  @action.bound
  public getCheckIn = (id: string) => {
    this.loading = true;
    this.props.checkInStore.getCheckIn(id).then((checkIn: ICheckIn) => {
      this.loading = false;
    });
  };

  @action.bound
  public changeSkipToCheckIn = () => {
    this.skipToCheckIn = true;
  };

  public renderStepContent = () => {
    let content;
    switch (this.stepStatus) {
      case 0:
        content = (
          <StepOne
            checkIn={this.checkIn}
            changeSkipToCheckIn={this.changeSkipToCheckIn}
          />
        );
        break;
      case 1:
        content = (
          <StepTwo checkIn={this.checkIn} skipToCheckIn={this.skipToCheckIn} />
        );
        break;
      case 2:
        content = <StepThree checkIn={this.checkIn} />;
        break;
      case 3:
        content = <StepsComplete checkIn={this.checkIn} />;
        break;
      default:
        content = (
          <p>
            Sorry, something went wrong. Please try again, or email us if the
            issue persists.
          </p>
        );
    }

    return content;
  };

  public renderCheckIn = () => {
    if (!this.checkIn) {
      return <Empty />;
    } else if (this.checkIn.deleted === 1) {
      return (
        <Alert
          message="Error: This check-in has been previously deleted."
          type="warning"
          showIcon
          className="inline-alert"
        />
      );
    } else {
      return (
        <>
          <div className="header">
            <h1 className="SiteHeader">
              <div className="IconWrapper">
                <ActiveReviewNReflectSVG />
              </div>
              Review & reflect
            </h1>
            {/* <button
              className="nextButton"
              style={{ minHeight: "40px" }}
              // onClick={() => this.newEmptyGoal()}
            >
              Next -&gt;
            </button> */}
          </div>
          {/* <Breadcrumb>
          <Breadcrumb.Item key="check-ins">
            <Link to="/check-ins">
              <FileDoneOutlined />
              &nbsp;
              Check-ins
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="check-in">
            <CalendarOutlined />
            &nbsp;
            Week ending {moment(this.checkIn.endDate).format('dddd, MMMM Do YYYY')}
          </Breadcrumb.Item>
        </Breadcrumb> */}

          <div className="check-in-steps mont S mlr-auto w-m-850 flexBoxRow justify-content-center">
            <Radio
              key={0}
              value={0}
              checked={this.stepStatus === 0}
              className={`radio-button ${
                this.stepStatus === 0 ? "orange-radio" : "grey-radio"
                // : this.stepStatus < 1
                // ? "grey-radio"
                // : "green-radio"
              }`}
              // disabled={this.stepStatus !== 0}
            >
              1. Review progress
            </Radio>
            <Radio
              key={1}
              value={1}
              checked={this.stepStatus === 1}
              className={`radio-button ${
                this.stepStatus === 1 ? "orange-radio" : "grey-radio"
              }`}
              // disabled={this.stepStatus !== 1}
            >
              2. Review outstanding
            </Radio>
            <Radio
              key={2}
              value={2}
              checked={this.stepStatus === 2}
              className={`radio-button ${
                this.stepStatus === 2 ? "orange-radio" : "grey-radio"
              }`}
              // disabled={this.stepStatus !== 2}
            >
              3. Reflect & orient
            </Radio>
            {/* <Input type="radio" id="toggle" checked={this.stepStatus > 1} />
            <Input type="radio" id="toggle" checked={this.stepStatus > 2} />
            <Input type="radio" id="toggle" checked={this.stepStatus > 3} /> */}
            {/* <Steps progressDot current={this.stepStatus}>
              <Step title="1. Review progress" />
              <Step title="2. Review outstanding" />
              <Step title="3. Reflect & orient" />
            </Steps> */}
          </div>

          {this.renderStepContent()}
        </>
      );
    }
  };

  render() {
    return (
      <IonPage className="scroll-page white-bg">
        <div className="main-content ion-padding">
          {this.loading ? (
            <IonSpinner className="loading" />
          ) : (
            this.renderCheckIn()
          )}
        </div>
      </IonPage>
    );
  }
}

export default withRouter(CheckIn);
