// Packages
import React, { Fragment } from "react";
import { IonButton, IonModal } from "@ionic/react";
import { computed, observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import _ from "lodash";
import { CheckCircleOutlined, RiseOutlined } from "@ant-design/icons";
// Components
import { Timeline, Select, notification } from "antd";
import ActivityCard from "../cards/ActivityCard/ActivityCard";
import EditActivityForm from "../forms/EditActivityForm/EditActivityForm";
import ActivityForm from "../../shared/forms/ActivityForm/ActivityForm";
// Styles
import "./GoalActivityTimeline.scss";
// Type Definitions
import { IGoal, IActivity } from "../interfaces";
import { AddSVG } from "../../components/svgComponents/AddSVG";

interface IProps {
  activityStore?: any;
  goal: IGoal;
}

const { Option } = Select;

@inject("activityStore")
@observer
class GoalActivityTimeline extends React.Component<IProps> {
  @computed get tasks() {
    const tasks = this.props.activityStore.tasks.filter(
      (task: IActivity) => task.goalId === this.props.goal.id
    );

    // Separate tasks by completionType
    const scheduledTasks = tasks.filter(
      (task: any) =>
        task.completionType === "scheduled" && task.status !== "complete"
    );
    const soonTasks = tasks.filter(
      (task: any) =>
        task.completionType === "soon" && task.status !== "complete"
    );
    const somedayTasks = tasks.filter(
      (task: any) =>
        task.completionType === "someday" && task.status !== "complete"
    );
    const completedTasks = tasks.filter(
      (task: any) => task.status === "complete"
    );

    // Sort each category of tasks
    scheduledTasks.sort((a: any, b: any) =>
      moment(a.completionDate).diff(moment(b.completionDate))
    );
    soonTasks.sort((a: any, b: any) =>
      moment(a.createdAt).diff(moment(b.createdAt))
    );
    somedayTasks.sort((a: any, b: any) =>
      moment(a.createdAt).diff(moment(b.createdAt))
    );
    completedTasks.sort((a: any, b: any) =>
      moment(a.updatedAt).diff(moment(b.updatedAt))
    );
    console.log("comp", completedTasks);

    // Combine sorted tasks into one array, with incomplete tasks first
    console.log("arr", [
      ...scheduledTasks,
      ...soonTasks,
      ...somedayTasks,
      ...completedTasks,
    ]);
    return [
      ...scheduledTasks,
      ...soonTasks,
      ...somedayTasks,
      ...completedTasks,
    ];
  }

  @computed get tasksByDay() {
    const day = (task: any) => moment(task.completionDate).format("ll");
    const dayTasks: any = _.groupBy<any[]>(this.tasks, day);
    return dayTasks;
  }

  @computed get routines() {
    return this.props.activityStore.routines.filter(
      (routine: IActivity) => routine.goalId === this.props.goal.id
    );
  }

  @computed get daysWorked() {
    return Object.keys(this.tasksByDay);
  }

  @computed get onlyPastTasks() {
    const today = moment().format("ll");
    const todayWorked = !!this.tasksByDay[today];
    const lastDayIndex = this.daysWorked.length - 1;
    const scheduledPastToday = this.daysWorked[lastDayIndex] > today;
    if (!todayWorked && !scheduledPastToday) {
      return true;
    } else {
      return false;
    }
  }

  @observable reverseOrder = false;

  public onChange = () => {
    this.reverseOrder = !this.reverseOrder;
  };

  public renderTimelineEmpty = () => {
    if (this.tasks.length < 1) {
      return (
        <Timeline.Item key="empty">
          <p className="subtitle not-found not-found-small">
            Click the + button to add a new task
          </p>
        </Timeline.Item>
      );
    }
  };

  public renderTimelineEnd = () => {
    if (this.props.goal.status === "complete") {
      return (
        <Timeline.Item
          key="complete"
          dot={<CheckCircleOutlined />}
          color="green"
        >
          <p className="small-title">
            {moment(this.props.goal.completedOn).format("ll")}
          </p>
          You completed this goal.
        </Timeline.Item>
      );
    }
  };

  @action.bound
  public closeActivityModal() {
    this.props.activityStore.updateActivityModal = false;
    this.props.activityStore.createActivityModal = false;
  }

  @action.bound
  public newEmptyActivity = async (when: string, type: string) => {
    this.props.activityStore.updateActivityType(type);
    this.props.activityStore.createActivityWhen = when;
    this.props.activityStore.activeActivityTemplate = {};
    this.props.activityStore.createActivityModal = true;
    this.props.activityStore.isGoalDetailForm = true;
  };

  @action.bound
  public closeNewActivityModal() {
    this.props.activityStore.createActivityModal = false;
  }

  render() {
    console.log("days", this.daysWorked);
    return (
      <>
        <div className="routine-container">
          <div className="title-container">
            <p className="small-title">ROUTINES</p>
            <div style={{ background: "white" }}>
              <button
                className="add-btn"
                onClick={() => {
                  if (this.props.goal.status === "complete")
                    return notification.error({
                       message: "Goal is already completed",
                     });
                  this.props.activityStore.createActivityType = "routine";
                  this.newEmptyActivity("", "routine");
                }}
              >
                <AddSVG color={"#0D6EF9"} />
              </button>
            </div>
          </div>
          {this.routines &&
            this.routines.map((routine: IActivity) => {
              return (
                <ActivityCard
                  key={routine.id}
                  activity={routine}
                  goalName={this.props.goal.name}
                  isGoalDetailForm={true}
                />
              );
            })}

          {this.routines.length < 1 ? (
            <p className="subtitle not-found not-found-small">
              Click the + button to add a new routine
            </p>
          ) : null}
          <hr />
          <br />
        </div>
        <div className="routine-container">
          <div className="title-container">
            <p className="small-title">TASKS</p>
            <div style={{ background: "white" }}>
              <button
                onClick={() => {
                  if (this.props.goal.status === "complete")
                   return notification.error({
                      message: "Goal is already completed",
                    });
                  this.props.activityStore.createActivityType = "task";
                  this.newEmptyActivity("", "task");
                }}
                className="add-btn"
              >
                <AddSVG color={"#0D6EF9"} />
              </button>
            </div>
          </div>
          {this.tasks.length < 1 ? (
            <p className="subtitle not-found not-found-small">
              Click the + button to add a new task
            </p>
          ) : null}
          {this.tasks.length > 0 &&
            this.tasks.map((task: any, index) => {
              return (
                <Fragment key={index}>
                  <ActivityCard
                    activity={task}
                    goalName={this.props.goal.name}
                    key={task.id}
                    isGoalDetailForm={true}
                    showFlag={true}
                  />
                </Fragment>
              );
            })}

          <hr />

          <IonModal
            cssClass={
              this.props.activityStore.createActivityType === "task"
                ? "activity-modal"
                : "routine-modal"
            }
            isOpen={this.props.activityStore.updateActivityModal}
            border-radius="7"
            backdropDismiss={false}
          >
            <div className="ion-padding modal-content overflow-y-scroll">
              <EditActivityForm closeActivityModal={this.closeActivityModal} />
            </div>
          </IonModal>
          <IonModal
            cssClass={
              this.props.activityStore.createActivityType === "task"
                ? "activity-modal"
                : "routine-modal"
            }
            isOpen={this.props.activityStore.createActivityModal}
            border-radius="7"
            backdropDismiss={false}
          >
            <div className="ion-padding modal-content overflow-y-scroll">
              <ActivityForm closeNewActivityModal={this.closeActivityModal} />
            </div>
          </IonModal>
        </div>
      </>
    );
  }
}

export default GoalActivityTimeline;
