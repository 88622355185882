// Packages
import React from "react";
import { action, computed } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import ActivityCard from "../cards/ActivityCard/ActivityCard";
// Type Definitions
import { IGoal, IActivity } from "../interfaces";
import { IonButton, IonModal } from "@ionic/react";
import { AddSVG } from "../../components/svgComponents/AddSVG";
import ActivityForm from "../forms/ActivityForm/ActivityForm";

interface IProps {
  activityStore?: any;
  goal: IGoal;
  startDate?: string;
  endDate?: string;
}

@inject("activityStore")
@observer
class GoalActivities extends React.Component<IProps> {
  @computed get tasks() {
    return this.props.activityStore.tasks.filter(
      (task: IActivity) => task.goalId === this.props.goal.id
    );
  }

  @computed get routines() {
    return this.props.activityStore.routines.filter(
      (routine: IActivity) => routine.goalId === this.props.goal.id
    );
  }

  @action.bound
  public newEmptyActivity = (when: string, type: string, goalId: string) => {
    this.props.activityStore.createActivityType = type;
    this.props.activityStore.createActivityWhen = when;
    this.props.activityStore.activeActivityTemplate = {};
    this.props.activityStore.createActivityModal = true;
    this.props.activityStore.isGoalDetailForm = true;
    this.props.activityStore.goalId = goalId;
  };

  @action.bound
  public closeActivityModal() {
    this.props.activityStore.updateActivityModal = false;
    this.props.activityStore.createActivityModal = false;
  }

  render() {
    return (
      <div className="activities-container">
        <div className="card-links header-content-wrapper">
          <p className="small-title goal-routines">ROUTINES</p>
          <IonButton
            size="small"
            color="none"
            onClick={() => this.newEmptyActivity("", "routine", this.props.goal.id)}
          >
            <AddSVG color="#0D6EF9" />
          </IonButton>
        </div>
        {this.routines.length > 0 && (
          <>
            {this.routines.map((routine: IActivity) => {
              return (
                <ActivityCard
                  key={routine.id}
                  activity={routine}
                  goalName={this.props.goal.name}
                  historicLookup={this.props.endDate}
                />
              );
            })}
          </>
        )}
        <div className="card-links header-content-wrapper">
          <p className="small-title goal-routines">TASKS</p>
          <IonButton
            size="small"
            color="none"
            onClick={() => this.newEmptyActivity("", "task", this.props.goal.id)}
          >
            <AddSVG color="#0D6EF9" />
          </IonButton>
        </div>
        {this.tasks.length > 0 && (
          <>
            {this.tasks.map((task: IActivity) => {
              console.log(task, "task");
              return (
                <ActivityCard
                  key={task.id}
                  activity={task}
                  goalName={this.props.goal.name}
                />
              );
            })}
          </>
        )}

        {/* {this.tasks.length < 1 && this.routines.length < 1 && (
          <>
            <p className="small-title">DESCRIPTION</p>
            {this.props.goal.description}
          </>
        )} */}
        <IonModal
          cssClass={
            this.props.activityStore.createActivityType === "task"
              ? "activity-modal"
              : "routine-modal"
          }
          isOpen={this.props.activityStore.createActivityModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            <ActivityForm closeNewActivityModal={this.closeActivityModal} goalId={this.props.activityStore.goalId}  />
          </div>
        </IonModal>
      </div>
    );
  }
}

export default GoalActivities;
