// Packages
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonMenu,
  IonList,
  IonMenuToggle,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonPopover,
  IonContent,
} from "@ionic/react";
import React, { MouseEvent, useState } from "react";
import { action, computed } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { track } from "../../../plugins/analytics";
import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
// Components
import { Popconfirm, message, notification } from "antd";
import { Link } from "react-router-dom";
// Styles
import "./GoalCard.scss";
// Type Definitions
import { IGoal, IActivity } from "../../../shared/interfaces";
import { withRouter } from "react-router-dom";
import { ellipsisVertical, menuOutline } from "ionicons/icons";
import EditSVG from "../../../components/svgComponents/EditSVG";
import DeleteSVG from "../../../components/svgComponents/DeleteSVG";
import { CompleteSVG } from "../../../components/svgComponents/CompleteSVG";
import TeamsSVG from "../../../components/svgComponents/TeamSVG";
import { FilledStarSVG } from "../../../components/svgComponents/FilledStarSVG";
import { NotFilledStarSVG } from "../../../components/svgComponents/NotFilledStarSVG";
import ShareSVG from "../../../components/svgComponents/ShareSVG";

// interface IProps<> {
//   goal: IGoal;
//   goalStore?: any;
//   userStore?: any;
//   activityStore?: any;
//   classNameForWrapper: string;
// }

@inject("goalStore", "userStore", "activityStore")
@observer
class GoalCard extends React.Component<any> {
  @computed get inspirationLink() {
    if (this.props.goal.templateId) {
      return `/inspiration?goalTemplateId=${this.props.goal.templateId}`;
    } else {
      return "/inspiration?onlyActivities=true";
    }
  }

  @action.bound
  public completeGoal = () => {
    const goal = {
      ...this.props.goal,
      status: "complete",
      completedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    track("Completed Goal");
    let action = "sendToComplete";
    action += this.props.goal.isPriority
      ? "FromPriority"
      : this.props.goal.isActive
      ? "FromActive"
      : "FromInactive";
    this.props.goalStore.updateGoal(goal, 1, 0, action).then((err: string) => {
      if (!err) {
        message.destroy();
        message.success("Goal completed!! Congrats!");
        this.props.userStore.showCelebration = true;
        setTimeout(() => {
          this.props.userStore.showCelebration = false;
        }, 3000); // Match antd message timing
      }
    });
  };

  public goToGoalDetails() {
    this.props.history.push({ pathname: `/goals/${this.props.goal.id}` });
  }

  @action.bound
  public editGoal = () => {
    this.props.goalStore.activeGoal = this.props.goal;
    this.props.goalStore.updateGoalModal = true;
  };

  @action.bound
  public async shareGoalActivities() {
    const key = "shareGoalMessage";
    message.loading({ content: "Sharing goal...", key });
    const action = this.props.goal.isPriority
      ? "refetchPriorityGoals"
      : this.props.goal.status === "complete"
      ? "refetchCompleteGoals"
      : this.props.goal.isActive
      ? "refetchActiveGoals"
      : "refetchInactiveGoals";
    const resetActivitiesError = await this.props.goalStore.shareGoal(
      this.props.goal.id,
      action
    );
    await this.props.activityStore._promisedTimeout(1000); // Ensure message readability
    if (resetActivitiesError) {
      message.error({
        content: "Error sharing goal. Please try again later.",
        key,
        duration: 2,
      });
    }
  }

  @action.bound
  public async resetGoalActivities() {
    const key = "deleteGoalMessage";
    message.loading({ content: "Removing goal...", key });
    const action = this.props.goal.isPriority
      ? "refetchPriorityGoals"
      : this.props.goal.status === "complete"
      ? "refetchCompleteGoals"
      : this.props.goal.isActive
      ? "refetchActiveGoals"
      : "refetchInactiveGoals";
    const resetActivitiesError =
      await this.props.goalStore.disconnectOrDeleteGoal(
        this.props.goal.id,
        true,
        action
      );
    await this.props.activityStore._promisedTimeout(1000); // Ensure message readability
    if (resetActivitiesError) {
      message.error({
        content: "Error removing goal. Please try again later.",
        key,
        duration: 2,
      });
    }
  }

  // componentDidMount() {
  //   this.props.userStore.fetchAssociation();
  // }

  // @action.bound
  // public async archiveGoal(key: string) {
  //   message.loading({ content: "Deleting goal...", key });
  //   const deleteGoalError = await this.props.goalStore.updateGoal(
  //     this.props.goal,
  //     0
  //   );
  //   track("Deleted Goal");
  //   await this.props.activityStore._promisedTimeout(1000); // Ensure message readability
  //   if (!deleteGoalError) {
  //     message.success({ content: "Goal deleted", key, duration: 2 });
  //   } else {
  //     message.error({
  //       content: "Error deleting goal. Please try again later.",
  //       key,
  //       duration: 2,
  //     });
  //   }
  // }
  @action.bound
  public handlePriorityClick = async (e: any) => {
    e.preventDefault();
    if (this.props.goal.isActive === false) {
      message.destroy();
      return message.warning("Inactive goals cannot be set as priority.");
    }
    if (
      this.props.goalStore.priorityGoals.length === 5 &&
      !this.props.goal.isPriority
    ) {
      message.destroy();
      return message.warning("You can only have 5 priority goals.");
    }
    this.props.goal.isPriority = !this.props.goal.isPriority;
    let action = this.props.goal.isPriority
      ? "sendToPriorityFromActive"
      : "sendToActiveFromPriority";

    await this.props.goalStore.updateGoal(
      this.props.goal,
      this.props.goal.isActive ? 1 : 0,
      this.props.goal.isPriority ? 1 : 0,
      action
    );
  };

  render() {
    //Firt line under return: passing specific wrapper alternate between styling with and without margins - alternating fixes masonry 2 card bug
    return (
      <Link
        to={`/goals/${this.props.goal.id}`}
        onClick={() => (this.props.goalStore.activeGoal = this.props.goal)}
        className={this.props.classNameForWrapper}
      >
        <IonCard className="goal-card">
          <IonCardHeader>
            <div className="card-menu-title">
              <IonCardTitle className="no-margin">
                <h2 className="title">{this.props.goal.name}</h2>
              </IonCardTitle>
              {/* <div className="card-buttons">
              {this.checkmarkIcon()}
              <EditOutlined onClick={() => this.editGoal()} className="icon-button" />
              <Popconfirm
              title="Are you sure you want to delete this goal?"
                onConfirm={this.resetGoalActivities}
                okText="Delete goal"
                cancelText="Keep at it"
                >
                <DeleteOutlined className="icon-button" />
                </Popconfirm>
              </div> */}
              <div
                className="kebabMenu controls-container"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {this.props.goal.mentorId && (
                  <Popconfirm
                    className="delete-popover"
                    title={
                      <div>
                        Shared with <br />
                        {this.props.goal.mentor.name && (
                          <>{this.props.goal.mentor.name}:</>
                        )}{" "}
                        {this.props.goal.mentor.email}
                      </div>
                    }
                    onConfirm={() => {
                      const action = this.props.goal.isPriority
                        ? "refetchPriorityGoals"
                        : this.props.goal.status === "complete"
                        ? "refetchCompleteGoals"
                        : this.props.goal.isActive
                        ? "refetchActiveGoals"
                        : "refetchInactiveGoals";
                      this.props.goalStore.disconnectOrDeleteGoal(
                        this.props.goal.id,
                        false,
                        action
                      );
                    }}
                    okText="Stop sharing"
                    cancelText="Cancel"
                  >
                    <button
                      className="shared"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <TeamsSVG color="#FF7E1D" />
                      Shared
                    </button>
                  </Popconfirm>
                )}
                <div className="menu-container">
                  {(this.props.goal.isActive === 1 ||
                    this.props.goal.isActive === true) && (
                    <button
                      onClick={(e) => {
                        this.handlePriorityClick(e);
                      }}
                      className="no-button"
                    >
                      {this.props.goal.isPriority ? (
                        <div className="priority-text">
                          <FilledStarSVG color={"#ffc773"} />
                        </div>
                      ) : (
                        <div className="priority-text">
                          <NotFilledStarSVG />
                        </div>
                      )}
                    </button>
                  )}
                  <KebabMenu
                    editGoal={this.editGoal}
                    resetGoalActivities={this.resetGoalActivities}
                    shareGoal={this.shareGoalActivities}
                    goToGoalDetails={this.goToGoalDetails}
                    completeGoal={this.completeGoal}
                    goal={this.props.goal}
                    activityStore={this.props.activityStore}
                    userStore={this.props.userStore}
                  />
                </div>
              </div>
            </div>
          </IonCardHeader>

          <IonCardContent>
            {this.props.goal.description && (
              <p className="goal-card-descr">{this.props.goal.description}</p>
            )}

            {/* <div className="card-links">
              <Link
                to={this.inspirationLink}
                className="text-button"
              >
                Find Activities
              </Link>

              <Link
                to={`/goals/${this.props.goal.id}`}
                className="text-button"
              >
                View History
              </Link>
            </div> */}
          </IonCardContent>
        </IonCard>
      </Link>
    );
  }
}

interface KebabMenuProps {
  editGoal: () => void;
  resetGoalActivities: () => void;
  goToGoalDetails: () => void;
  shareGoal: () => void;
  completeGoal: () => void;
  goal: IGoal;
  activityStore: any;
  userStore: any;
}

interface KebabMenuState {
  showPopover: boolean;
  event?: Event;
}

class KebabMenu extends React.Component<KebabMenuProps, KebabMenuState> {
  constructor(props: KebabMenuProps) {
    super(props);
    this.state = {
      showPopover: false,
    };
  }

  public hasIncompletedActivities() {
    const { id } = this.props.goal;
    const { tasks, routines } = this.props.activityStore;
    const hasIncompletedTasks = tasks.some(
      (task: IActivity) => task.goalId === id
    );
    const hasIncompletedRoutines = routines.some(
      (routine: IActivity) => routine.goalId === id
    );
    return hasIncompletedRoutines || hasIncompletedTasks;
  }

  handlePopoverOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    this.setState({ showPopover: true, event: e.nativeEvent });
  };

  handlePopoverClose = () => {
    this.setState({ showPopover: false });
  };

  handleEditGoalClick = () => {
    this.props.editGoal();
    this.setState({ showPopover: false });
  };

  handleResetGoalActivitiesClick = () => {
    this.props.resetGoalActivities();
    this.setState({ showPopover: false });
  };

  handleShareGoalClick = () => {
    console.log(this.props.userStore.association.mentor.name);
    this.props.shareGoal();
    this.setState({ showPopover: false });
  };

  render() {
    return (
      <>
        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.event}
          onDidDismiss={this.handlePopoverClose}
        >
          <IonList className="small-width">
            <IonButton
              onClick={(e) => {
                e.preventDefault();
                this.handleEditGoalClick();
              }}
              expand="full"
              className="no-background"
            >
              <div className="edit">
                <EditSVG /> Edit
              </div>
            </IonButton>
            {this.hasIncompletedActivities() ? (
              <Popconfirm
                title="You have uncompleted tasks and/or routines for this goal. Please take a second to either delete or complete them"
                onConfirm={() => this.props.goToGoalDetails()}
                okText="Okay"
                cancelText="Cancel"
              >
                <IonButton expand="full" className="no-background">
                  <div className="edit">
                    <div className="flexBoxRow">
                      <CompleteSVG /> Complete
                    </div>
                  </div>
                </IonButton>
              </Popconfirm>
            ) : (
              <IonButton
                expand="full"
                className="no-background"
                onClick={() =>
                  this.hasIncompletedActivities()
                    ? null
                    : this.props.completeGoal()
                }
              >
                <div className="edit">
                  <div className="flexBoxRow">
                    <CompleteSVG /> Complete
                  </div>
                </div>
              </IonButton>
            )}
            {!this.props.goal.mentorId &&
              this.props.userStore.association.mentor.email && (
                <Popconfirm
                  className="delete-popover"
                  title={`Share this goal with ${
                    this.props.userStore.association.mentor.name &&
                    this.props.userStore.association.mentor.name + ": "
                  }${this.props.userStore.association.mentor.email}`}
                  onConfirm={() => {
                    this.handleShareGoalClick();
                  }}
                  okText="Share goal"
                  cancelText="Cancel"
                >
                  <IonButton
                    expand="full"
                    className="no-background"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="edit">
                      <div className="flexBoxRow">
                        <ShareSVG />
                        Share
                      </div>
                    </div>
                  </IonButton>
                </Popconfirm>
              )}
            <Popconfirm
              className="delete-popover"
              title="Are you sure you want to delete this goal?"
              onConfirm={() => {
                this.handleResetGoalActivitiesClick();
              }}
              okText="Delete goal"
              cancelText="Keep at it"
            >
              <IonButton
                expand="full"
                className="no-background"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="delete">
                  <DeleteSVG />
                  Delete
                </div>
              </IonButton>
            </Popconfirm>
          </IonList>
        </IonPopover>
        <button
          className="menu-button"
          onClick={(e: any) => this.handlePopoverOpen(e)}
        >
          <IonIcon icon={ellipsisVertical} />
        </button>
      </>
    );
  }
}

export default withRouter(GoalCard);
