// Packages
import React from "react";
import { IonModal } from "@ionic/react";
import { autorun, action, computed, observable } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import _ from "lodash";
import { BulbOutlined, CheckOutlined, DeleteOutlined } from "@ant-design/icons";
// Components
import { Card, Alert } from "antd";
import EditActivityForm from "../../../shared/forms/EditActivityForm/EditActivityForm";
import GoalReviewCard from "../GoalReviewCard/GoalReviewCard";
import StepNavigation from "../StepNavigation/StepNavigation";
// Type Definitions
import { IActivity, ICheckIn, IGoal } from "../../../shared/interfaces";
import EditGoalForm from "../../../shared/forms/EditGoalForm/EditGoalForm";
import ActivityCard from "../../../shared/cards/ActivityCard/ActivityCard";
import moment from "moment";

interface IProps {
  checkInStore?: any;
  activityStore?: any;
  goalStore?: any;
  checkIn: ICheckIn;
  skipToCheckIn: boolean;
}

@inject("checkInStore", "activityStore", "goalStore")
@observer
class StepTwo extends React.Component<IProps> {
  @observable public loadingCount = 0;
  // @observable public goalsNotWorked: IGoal[] = [];
  // @computed get goalsNotWorked() {
  //   const goalIds = _.map(this.props.activityStore.activities, "goalId");
  //   console.log("goalIds", goalIds);
  //   const goals = this.props.goalStore.goals.filter((goal: IGoal) => {
  //     return (
  //       goalIds.indexOf(goal.id) < 0 &&
  //       goal.status === "incomplete" &&
  //       goal.isActive === 1
  //     );
  //   });
  //   return goals;
  // }
  // @action async fetchGoalsNotWorked() {
    // await this.props.goalStore.getGoals("isActive=1");
    // console.log("fetchGoalsNotWorked");
    // const goalIds = _.map(this.props.activityStore.activities, "goalId");
    // // const goalIds = [
    // //   "fb5c7c90-360a-11ef-b013-73b1972a6e6d",
    // //   "ee190420-15dc-11ef-a5ca-c3c7a3c2126b",
    // // ];
    // console.log(goalIds, "goalIds");
    // const goals = await this.props.goalStore.goals.filter((goal: IGoal) => {
    //   return (
    //     goalIds.indexOf(goal.id) < 0 &&
    //     goal.status === "incomplete" &&
    //     goal.isActive === 1 &&
    //     moment(goal.createdAt) <= moment(this.props.checkIn.endDate)
    //   );
    // });
    // console.log(goals);
  // }
  @computed get goalsNotWorked() {
    console.log(this.props.checkInStore.activeCheckIn.data, "activeCheckIn");
    return this.props.checkInStore.activeCheckIn.data;
  }

  @computed get emptyGoals() {
    return this.loadingCount > 3 && this.goalsNotWorked && this.goalsNotWorked.length < 1;
  }

  @disposeOnUnmount
  public stepOneAutorun = autorun(() => {
    // this.getActivities();
    // this.getGoals();
    // this.fetchGoalsNotWorked();
    this.loadingCount = 4;
  });
  @computed get independentActivities() {
    return this.props.checkInStore.independentActivities;
    // return this.props.activityStore.activities.filter((activity: IActivity) => {
    //   return activity.goalId === "0";
    // });
  }

  // @action.bound
  // public getActivities() {
  //   const {
  //     activityStore,
  //     checkIn: { startDate, endDate },
  //   } = this.props;
  //   activityStore
  //     .getActivities("task", startDate, endDate, undefined, "scheduled")
  //     .then(() => {
  //       this.loadingCount += 1;
  //       this.props.activityStore.task =
  //         this.props.activityStore.activities.filter(
  //           (task: IActivity) => task.status === "incomplete"
  //         );
  //     });
  //   activityStore.getActivities("routine", startDate, endDate).then(() => {
  //     this.loadingCount += 1;
  //     this.props.activityStore.routines =
  //       this.props.activityStore.activities.filter((routine: IActivity) => {
  //         const dayOf = moment(this.props.checkIn.endDate).format("YYYY-MM-DD");
  //         const dayAfter = moment(this.props.checkIn.endDate)
  //           .add(1, "days")
  //           .format("YYYY-MM-DD");
  //         if (routine.history) {
  //           if (routine["history"][dayOf]) {
  //             if (routine["history"][dayOf].timesCompleted === 0) {
  //               return routine["history"][dayOf];
  //             }
  //           } else if (routine["history"][dayAfter]) {
  //             if (routine["history"][dayAfter].timesCompleted === 0) {
  //               return routine["history"][dayAfter];
  //             }
  //           }
  //         }
  //       });
  //   });
  // }

  // @action.bound
  // public getGoals() {
  //   console.log("getGoals");
  //   this.props.goalStore
  //     .getGoals("isActive=1")
  //     .then(() => (this.loadingCount += 1));
  // }

  public renderNullExplainer = () => {
    if (this.emptyGoals) {
      return (
        <div className="center-text">
          <br />
          <Alert
            message="Nice! It looks like you progressed on everything this week. Proceed to complete your check-in."
            type="success"
            showIcon
            className="inline-alert center"
          />
        </div>
      );
    }
  };

  public renderSubtitle = () => {
    if (!this.emptyGoals) {
      return (
        <p className="subTitle-review">
          That’s okay! We can’t make progress on everything, but it’s important
          to evaluate what's still important to us.
        </p>
      );
    }
  };

  public renderLoading = () => {
    if (this.loadingCount < 3) {
      return (
        <div className="check-in-card-wrapper">
          <Card className="goal-review-card" loading />
        </div>
      );
    }
  };

  @action.bound
  public closeActivityModal() {
    this.props.activityStore.updateActivityModal = false;
  }

  @action.bound
  public closeGoalModal() {
    this.props.goalStore.updateGoalModal = false;
  }

  public renderExtraActivities = () => {
    if (this.independentActivities.length > 0) {
      return (
        <div style={{ marginTop: "2rem" }}>
          <div className="center-text">
            <h3 className="step-title">
              And here is everything else you worked on
            </h3>
            <p className="step-description">
              Showing task & routines without goal assigned to them.
            </p>
          </div>

          <div className="check-in-card-wrapper" key="independent-activities">
            {this.independentActivities.map((activity: IActivity) => {
              return (
                <ActivityCard
                  key={activity.id}
                  activity={activity}
                  goalName=""
                  historicLookup={this.props.checkIn.endDate}
                />
              );
            })}
            <hr />
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="step-two">
        <div className="center-text">
          <h3 className="title-review">
            Here are the goals you didn’t work on this week.
          </h3>
          {this.renderSubtitle()}
        </div>
        {this.renderNullExplainer()}
        {this.renderLoading()}

        {this.goalsNotWorked &&
          this.goalsNotWorked.map((goal: IGoal) => {
            console.log(goal, "inside map");
            return (
              <div className="check-in-card-wrapper" key={goal.id}>
                <GoalReviewCard
                  goal={goal}
                  loading={this.loadingCount < 3}
                  startDate={this.props.checkIn.startDate}
                  endDate={this.props.checkIn.endDate}
                />
              </div>
            );
          })}
        {this.renderExtraActivities()}
        <StepNavigation
          checkIn={this.props.checkIn}
          title="When you're ready, head over to Step Three."
          allowRevertStep
          allowIncrementStep
          skipToCheckIn={this.props.skipToCheckIn}
        />

        <IonModal
          isOpen={this.props.activityStore.updateActivityModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            {/* <button className="x-button" onClick={() => this.closeActivityModal()} >
              x
            </button> */}
            <EditActivityForm closeActivityModal={this.closeActivityModal} />
          </div>
        </IonModal>
        <IonModal
          cssClass={
            this.props.goalStore.activeGoal.status === "complete"
              ? "goal-modal-complete"
              : "goal-modal"
          }
          isOpen={this.props.goalStore.updateGoalModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            <EditGoalForm closeGoalModal={this.closeGoalModal} />
          </div>
        </IonModal>
      </div>
    );
  }
}

export default StepTwo;
