// Packages
import React from "react";
import { observable, computed, action, autorun } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import _ from "lodash";
import { track } from "../../../plugins/analytics";
// Components
import DatePicker from "../../DatePicker/DatePicker";
import {
  CalendarOutlined,
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
import {
  Popconfirm,
  Input,
  Button,
  Alert,
  InputNumber,
  message,
  Form,
  Calendar,
  Select,
} from "antd";
// Type Definitions
import { INewActivity, IGoal } from "../../interfaces";
import { IonSelect, IonSelectOption } from "@ionic/react";
import moment from "moment";
import FlagSVG from "../../../components/svgComponents/FlagSVG";
import { HistorySVG } from "../../../components/svgComponents/HistorySVG";
import "./ActivityForm.scss";

interface IProps {
  activityStore?: any;
  goalStore?: any;
  closeNewActivityModal: () => void;
  changeTimeLine?: (when: string | undefined) => void;
  goalId?: string;
}

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

@inject("activityStore", "goalStore")
@observer
class ActivityForm extends React.Component<IProps> {
  // Add a new state to store the selected date temporarily
  @observable tempDate: moment.Moment | null = null;
  @observable public loading = false;
  @observable public datePickerOpened = false;
  @observable public isDropdownOpen = false;
  @observable public error = null;
  @observable public newActivity: INewActivity = this.initActivity();

  @computed get activityTemplate() {
    return this.props.activityStore.activeActivityTemplate;
  }

  @disposeOnUnmount
  public resetState = autorun(() => {
    const { createActivityModal } = this.props.activityStore;
    console.log(this.props.goalId);
    if (createActivityModal) {
      this.newActivity = this.initActivity();
      if (Object.entries(this.activityTemplate).length > 0) {
        this.newActivity.name = this.activityTemplate.name;
        this.newActivity.description = this.activityTemplate.description;
        this.newActivity.type = this.activityTemplate.type;
        this.newActivity.templateId = this.activityTemplate.id;
        this.newActivity.frequency = this.activityTemplate.frequency;
      }
    }
  });

  @action.bound
  public handleDropdownVisibleChange = (open: boolean) => {
    this.setState({ isDropdownOpen: open });
  };

  @action.bound
  public handleSubmit = async (typeTitle: string) => {
    this.loading = true;
    const resError = await this.props.activityStore.createActivity(
      this.newActivity
    );
    if (this.props.changeTimeLine)
      this.props.changeTimeLine(
        this.newActivity.completionType === "soon" ||
          this.newActivity.completionType === "someday"
          ? this.newActivity.completionType
          : "thisWeek"
      );
    if (this.newActivity.templateId) {
      track("Added Activity From Inspiration", {
        type: typeTitle,
        goalAssociated: this.newActivity.goalId !== null,
        activityTemplateId: this.newActivity.templateId,
        activityTemplateName: this.newActivity.name,
      });
    } else if (this.props.activityStore.isGoalDetailForm) {
      track("Added Activity From Goal Details", {
        type: typeTitle,
      });
    } else {
      track("Added Activity", {
        type: typeTitle,
        goalAssociated: this.newActivity.goalId !== null,
      });
    }
    this.error = resError;
    this.loading = false;
    if (!this.error) {
      this.props.activityStore.createActivityModal = false;
      message.success(`${typeTitle} created!`);
    }
  };

  public isSubmitButtonDisabled = () => {
    return (
      this.newActivity.name === undefined ||
      this.newActivity.name === "" ||
      this.newActivity.completionDate === null ||
      this.newActivity.completionDate === "0"
    );
  };

  public renderSubmitBtn(typeTitle: string) {
    if (this.loading) {
      return (
        <Button type="primary" className="form-submit-button" disabled>
          <LoadingOutlined />
        </Button>
      );
    } else if (
      !this.isSubmitButtonDisabled() &&
      this.newActivity.goalId === "0"
    ) {
      return (
        <Popconfirm
          title={
            typeTitle === "Task"
              ? "Hold up! This task is not tied to a goal."
              : "Hold up! This routine is not tied to a goal."
          }
          onCancel={() => {
            track("Goal Association Nudge Ignored");
            this.handleSubmit(typeTitle);
          }}
          okText="Wait, I'll assign a goal"
          cancelText="Add it with no goal"
          disabled={this.isSubmitButtonDisabled()}
        >
          <Button type="primary" className="form-submit-button">
            Create {typeTitle}
          </Button>
        </Popconfirm>
      );
    } else {
      return (
        <Button
          type="primary"
          className="form-submit-button"
          disabled={this.isSubmitButtonDisabled()}
          onClick={() => this.handleSubmit(typeTitle)}
        >
          Create {typeTitle}
        </Button>
      );
    }
  }

  public renderFields() {
    const type = this.props.activityStore.createActivityType;
    if (type === "routine")
      return (
        <Form.Item style={{ marginBottom: "0px", flex: "1", display: "flex" }}>
          <div className="actions-container">
            <div className="occurence-container">
              <HistorySVG />
              Set occurrence
            </div>
            <div className="counter-container">
              <Button
                className="plus-minus-button"
                onClick={() =>
                  this.newActivity.frequency
                    ? this.newActivity.frequency > 1 &&
                      this.newActivity.frequency--
                    : (this.newActivity.frequency = 1)
                }
                icon={<MinusOutlined />}
                size="small"
              />
              <input
                className="routine-form-input"
                name="frequency"
                value={this.newActivity.frequency}
                min={1}
                required
                onChange={(e: any) => {
                  this.handleInputChange("frequency", e.target.value);
                }}
              />
              <Button
                className="plus-minus-button"
                onClick={() =>
                  this.newActivity.frequency
                    ? this.newActivity.frequency >= 1 &&
                      this.newActivity.frequency++
                    : null
                }
                icon={<PlusOutlined />}
                size="small"
              />
              <div className="routine-form-item-text">PER WEEK</div>
            </div>
          </div>
        </Form.Item>
      );
    return (
      <Form.Item
        style={{ marginBottom: "0px", minWidth: "fit-content", width: "4rem" }}
      >
        {this.newActivity.completionDate !== "scheduled" &&
          this.datePickerOpened === false && (
            <Select
              className="date-picker-select"
              dropdownClassName="date-picker-dropdown"
              defaultValue={
                <>
                  <CalendarOutlined style={{ fill: "#413D45" }} /> When
                </>
              }
              value={
                this.newActivity.completionDate === "0" ? (
                  <>
                    <CalendarOutlined style={{ fill: "#413D45" }} /> When
                  </>
                ) : (
                  this.newActivity.completionDate
                )
              }
              onChange={(e) => {
                if (e === "scheduled") {
                  this.tempDate = moment();
                  this.datePickerOpened = true;
                }
                console.log(e);
                this.handleInputChange("completionDate", e);
              }}
              showArrow={false}
              dropdownStyle={{ transform: "translateY(-1)" }}
              onFocus={() => (this.isDropdownOpen = true)}
              onBlur={() => (this.isDropdownOpen = false)}
            >
              {!this.isDropdownOpen && (
                <Select.Option className="select-option" key="0" value="0">
                  <CalendarOutlined style={{ fill: "#413D45" }} /> When
                </Select.Option>
              )}
              <Select.Option
                className="select-option"
                key="1"
                value="scheduled"
              >
                Scheduled
              </Select.Option>
              <Select.Option className="select-option" key="2" value="soon">
                Soon
              </Select.Option>
              <Select.Option className="select-option" key="3" value="someday">
                Someday
              </Select.Option>
            </Select>
          )}
        {/* <IonSelect
            defaultValue="0"
            value={this.newActivity.completionDate}
            onIonChange={(e) =>
              this.handleInputChange("completionDate", e.detail.value)
            }
          >
            <IonSelectOption key="0" value="0">
              <CalendarOutlined className="active" /> When
            </IonSelectOption>
            <IonSelectOption key="1" value="Scheduled">
              Scheduled
            </IonSelectOption>
            <IonSelectOption key="2" value="Soon">
              Soon
            </IonSelectOption>
            <IonSelectOption key="3" value="Someday">
              Someday
            </IonSelectOption>
          </IonSelect> */}
        {this.newActivity.completionDate !== "soon" &&
          this.newActivity.completionDate !== "someday" &&
          this.newActivity.completionDate !== "0" &&
          this.datePickerOpened && (
            <DatePicker
              customClassName="new-date-picker"
              defaultValue={moment()}
              defaultPickerValue={moment()}
              value={
                this.newActivity.completionDate === "scheduled"
                  ? moment()
                  : moment(this.newActivity.completionDate)
              }
              onChange={(value: any) => {
                console.log(
                  "Date Picker Value: ",
                  moment(value).format("YYYY-MM-DD")
                );
                if (moment(value).format("YYYY-MM-DD") !== "Invalid date") {
                  this.tempDate = null;
                  this.handleInputChange("completionDate", moment(value));
                } else {
                  this.tempDate = null;
                  this.datePickerOpened = false;
                  this.handleInputChange("completionDate", "0");
                }
              }}
              onOpenChange={(isOpen: boolean) => {
                if (!isOpen) {
                  // The date picker has been closed
                  if (this.tempDate) {
                    // A date was selected
                    this.handleInputChange(
                      "completionDate",
                      this.tempDate.format("YYYY-MM-DD")
                    );
                  }
                }
              }}
            />
          )}
        {/* {this.props.goalStore.activeGoals.map((goal: IGoal) => (
            <IonSelectOption
              className="ionic-goal-picker"
              key={goal.id}
              value={goal.id}
            >
              {goal.name}
            </IonSelectOption>
          ))} */}

        {/* <DatePicker
          defaultValue={this.newActivity.completionDate}
          defaultPickerValue={this.newActivity.completionDate}
          value={this.newActivity.completionDate}
          onChange={(value: any) =>
            this.handleInputChange("completionDate", value)
          }
        /> */}
      </Form.Item>
    );
  }

  @action.bound
  public handleInputChange(key: string, value: any) {
    console.log(this.newActivity.goalId);
    switch (key) {
      case "name":
        this.newActivity.name = value;
        break;
      case "description":
        this.newActivity.description = value;
        break;
      case "goalId":
        this.newActivity.goalId = value;
        break;
      case "completionDate":
        if (value === "scheduled") {
          this.newActivity.completionDate = value;
        } else {
          this.newActivity.completionDate = value;
          this.newActivity.completionType = value;
        }
        break;
      case "frequency":
        const frequency = Number.isInteger(Number(value)) ? Number(value) : 1;
        this.newActivity.frequency = frequency;
        break;

      default:
        console.log("Unknown form value change");
    }
  }

  public initActivity() {
    const { createActivityWhen, createActivityType, isGoalDetailForm } =
      this.props.activityStore;
    const { activeGoal } = this.props.goalStore;
    const upcomingDate = moment().endOf("isoWeek").add(30, "days");
    const initialActivity = {
      name: "",
      description: "",
      type: createActivityType,
      status: "incomplete",
      goalId: isGoalDetailForm
        ? this.props.goalId
          ? this.props.goalId
          : activeGoal.id
        : "0",
      isActive: 1,
      userId: this.props.activityStore.userId,
    };

    if (createActivityType === "routine") {
      return { ...initialActivity, frequency: 1 };
    } else {
      const completionDate = "0";
      return { ...initialActivity, completionDate };
    }
  }

  render() {
    const { TextArea } = Input;
    const typeTitle = _.startCase(this.props.activityStore.createActivityType);

    return (
      <>
        <div className="goal-form-wrapper">
        <div className="error-container">
          {this.error ? (
            <Alert message={this.error} type="error" showIcon />
          ) : (
            ""
          )}
          </div>
          <h3 className="montX XL">New {typeTitle}</h3>

          <Form className="goal-form" {...formItemLayout}>
            <Form.Item>
              <label className="label" htmlFor="name">
                Name*
              </label>
              <Input
                name="name"
                placeholder="Name (required)"
                value={this.newActivity.name}
                required
                onChange={(event: any) =>
                  this.handleInputChange("name", event.currentTarget.value)
                }
              />
            </Form.Item>

            <Form.Item>
              <label className="label" htmlFor="description">
                Description
              </label>
              <TextArea
                style={{ minHeight: "90px", resize: "none" }}
                name="description"
                placeholder="Description"
                value={this.newActivity.description}
                onChange={(event: any) =>
                  this.handleInputChange(
                    "description",
                    event.currentTarget.value
                  )
                }
              />
            </Form.Item>
            <Form.Item>
              <div
                className={`${"flexBoxRow"} ${
                  this.props.activityStore.createActivityType === "routine"
                    ? "flex-wrap"
                    : ""
                }`}
              >
                <div
                  className={`${"ant-input-mimic"} 
                ${
                  this.props.activityStore.createActivityType === "routine"
                    ? "fullWidth"
                    : ""
                }`}
                >
                  <IonSelect
                    defaultValue="0"
                    value={this.newActivity.goalId}
                    onIonChange={(e) =>
                      this.handleInputChange("goalId", e.detail.value)
                    }
                    disabled={this.props.activityStore.isGoalDetailForm}
                    className={
                      this.newActivity.type === "task" ? "goal-text-select" : ""
                    }
                  >
                    <IonSelectOption key="0" value="0">
                      <FlagSVG className={"active"} /> No goal assigned
                    </IonSelectOption>

                    {this.props.goalStore.goals.map((goal: IGoal) => {
                      if (goal.status === "complete" && this.newActivity.goalId !== goal.id) {
                        return null;
                      } else if(goal.isActive === 0 && this.newActivity.goalId !== goal.id) {
                        return null;
                      }
                      return (
                        <IonSelectOption
                          className="ionic-goal-picker"
                          key={goal.id}
                          value={goal.id}
                        >
                          {goal.name}
                        </IonSelectOption>
                      );
                    })}
                    {}
                  </IonSelect>
                </div>
                {this.renderFields()}
              </div>
            </Form.Item>

            <div className="form-buttons-wrapper">
              <Button
                className="cancel-button"
                onClick={this.props.closeNewActivityModal}
              >
                Cancel
              </Button>
              {this.renderSubmitBtn(typeTitle)}
            </div>
          </Form>
        </div>
      </>
    );
  }
}

export default ActivityForm;
