/* eslint-disable @typescript-eslint/no-unused-expressions */
// Packages
import React from "react";
import {
  IonPage,
  IonModal,
  IonButton,
  IonSpinner,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { autorun, action, computed, observable } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import moment from "moment";
// Components
import ActivityForm from "../../shared/forms/ActivityForm/ActivityForm";
import EditActivityForm from "../../shared/forms/EditActivityForm/EditActivityForm";
import ActivityCard from "../../shared/cards/ActivityCard/ActivityCard";
import { activityComparator } from "./activityComparator";
// Styles
import "./Dashboard.scss";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";
import { IGoal, IActivity } from "../../shared/interfaces";
import { ActiveActionsSVG } from "../../components/svgComponents/ActiveActionsSVG";
import { CrossSVG } from "../../components/svgComponents/CrossSVG";

interface IProps extends RouteComponentProps {
  userStore: any;
  activityStore: any;
  goalStore: any;
}
interface IState {
  isToggled: boolean;
}

@inject("userStore", "activityStore", "goalStore")
@observer
class Dashboard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isToggled: false,
    };
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      isToggled: !prevState.isToggled,
    }));
  };

  @observable public loading = false;
  @observable public loadingTasks = false;
  @observable public showFarOut = false;
  @observable public taskActivities: IActivity[] = [];
  @observable public taskTimeLine = "thisWeek";

  @computed get goals() {
    return this.props.goalStore.goals;
  }

  @computed get tasks() {
    return this.props.activityStore.tasks;
  }

  @computed get routines() {
    return this.props.activityStore.routines;
  }

  @computed get thisWeeksTasks() {
    return this.tasks.filter((task: IActivity) => {
      const completionDate = moment(task.completionDate);
      const completedOn = moment(task.completedOn);
      const startOfThisWeek = moment().startOf("isoWeek");
      const isOverdue =
        task.status === "incomplete" &&
        completionDate.isBefore(startOfThisWeek);
      const isThisWeek = completionDate.isoWeek() === moment().isoWeek();
      const completedThisWeek =
        task.status === "complete" && completedOn.isAfter(startOfThisWeek);
      return isThisWeek || completedThisWeek || isOverdue;
    });
  }

  @computed get scheduledTasks() {
    return this.tasks.filter((task: IActivity) => {
      return (
        task.completionType === "scheduled" &&
        !!task.completionDate &&
        task.status === "incomplete"
      );
    });
  }

  @computed get completedTasks() {
    console.log("tasks", this.tasks);
    return this.tasks.filter((task: IActivity) => {
      return task.status === "complete";
    });
  }

  @computed get soonTasks() {
    return this.tasks.filter((task: IActivity) => {
      console.log("soon", task);
      return task.completionType === "soon" && task.status === "incomplete";
    });
  }

  @computed get somedayTasks() {
    return this.tasks.filter((task: IActivity) => {
      return task.completionType === "someday" && task.status === "incomplete";
    });
  }

  @disposeOnUnmount
  public dashboardAutorun = autorun(() => {
    this.getGoals();
    this.getActivities();
  });

  @action.bound
  public goalFromId(id: string) {
    const goal = this.goals.find((goal: IGoal) => goal.id === id);
    if (goal === undefined) return {};
    return goal;
  }

  @action.bound
  public newEmptyActivity = (when: string, type: string) => {
    this.props.activityStore.createActivityType = type;
    this.props.activityStore.createActivityWhen = when;
    this.props.activityStore.activeActivityTemplate = {};
    this.props.activityStore.createActivityModal = true;
    this.props.activityStore.isGoalDetailForm = false;
  };

  @action.bound
  public getGoals = () => {
    this.props.goalStore.getGoals();
  };

  @action.bound
  public getActivities = async () => {
    if (this.props.activityStore.activities.length < 1) {
      this.loadingTasks = true;
      this.loading = true;
    }

    const { startOfWeek, endOfWeek } = await this.props.activityStore
      .getWeekStartAndEnd;
    this.props.activityStore
      .getActivities("task", undefined, undefined, undefined, "scheduled")
      .then(() => {
        this.loadingTasks = false;
      });

    this.props.activityStore.getActivities("routine").then(() => {
      this.loading = false;
    });
  };

  @action.bound
  public mapTasks = (tasks: IActivity[], when: string, goals: IGoal[]) => {
    if (tasks.length === 0) {
      return <p className="subtitle not-found">No tasks found</p>;
    } else {
      const showYear = when === "someday" ? true : false;
      return tasks.map((task: IActivity) => {
        const goalName = this.goalFromId(task.goalId).name;
        if (task.goalId === "0" || (task.goal && task.goal.isActive === 1)) {
          return (
            <ActivityCard
              key={task.id}
              activity={task}
              goalName={goalName}
              showYear={showYear}
              showFlag={true}
            />
          );
        } else return null;
      });
    }
  };

  mapRoutines = (routines: IActivity[]) => {
    if (routines.length === 0)
      return <p className="subtitle not-found">No routines found</p>;
    return routines
      .slice()
      .sort(activityComparator)
      .map((routine: IActivity) => {
        const goalName = this.goalFromId(routine.goalId).name;
        if (
          (routine.goal && routine.goal.isActive === 1) ||
          (routine.goalId === "0")
        ) {
          return (
            <ActivityCard
              key={routine.id}
              activity={routine}
              goalName={goalName}
            />
          );
        } else return null;
      });
  };

  @action.bound
  public closeNewActivityModal() {
    this.props.activityStore.createActivityModal = false;
  }

  @action.bound
  public changeTimeLine = (when: string | undefined) => {
    if (when) this.taskTimeLine = when;
    else this.taskTimeLine = "thisWeek";
  };

  @action.bound
  public closeActivityModal() {
    this.props.activityStore.updateActivityModal = false;
  }

  public switchTasks = async (when: string) => {
    this.loadingTasks = true;
    this.taskTimeLine = when;
    if (when === "thisWeek") {
      this.taskActivities = await this.props.activityStore.getActivities(
        "task",
        undefined,
        undefined,
        undefined,
        "scheduled"
      );
    } else if (when === "soon") {
      this.taskActivities = await this.props.activityStore.getActivities(
        "task",
        "",
        undefined,
        undefined,
        "soon"
      );
    } else if (when === "someday") {
      this.taskActivities = await this.props.activityStore.getActivities(
        "task",
        "",
        undefined,
        undefined,
        "someday"
      );
    }
    this.loadingTasks = false;
  };

  render() {
    return (
      <IonPage className="scroll-page ion-padding">
        <div className="content-container-actions">
          <div className="header">
            <h1 className="SiteHeader">
              <div className="IconWrapper">
                <ActiveActionsSVG />
              </div>
              Actions
            </h1>
            <div className="buttonsContainer">
              <div
                className={`create-button ${this.state.isToggled && "cross"}`}
                onClick={this.handleToggle}
              >
                <CrossSVG color={"white"} />
                {/* Create <CaretDownFilled className="button-space" /> */}
              </div>
              {this.state.isToggled ? (
                <div className="buttons zIndex">
                  <IonButton
                    size="small"
                    className="custom-button in-view"
                    onClick={() => {
                      this.handleToggle();
                      this.newEmptyActivity("", "task");
                    }}
                  >
                    Task
                    {/* <div className="plus-sign"></div> Add Task */}
                  </IonButton>
                  <IonButton
                    size="small"
                    className="custom-button in-view"
                    onClick={() => {
                      this.handleToggle();
                      this.newEmptyActivity("", "routine");
                    }}
                  >
                    Routine
                    {/* <div className="plus-sign"></div> Add Routine */}
                  </IonButton>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="dashboard-row">
            <div className="dashboard-column">
              <div className="">
                {/* main-content = provides padding consistent throughout site pages to each column within dashboard*/}
                <div className="dashboard-header">
                  <span className="SiteHeaderSM">TASKS</span>
                  <IonSegment
                    value={this.taskTimeLine}
                    className="width-control"
                  >
                    <span>
                      <IonSegmentButton
                        onClick={() => this.switchTasks("thisWeek")}
                        className="shorterButton"
                        value="thisWeek"
                      >
                        <span className="montX M capitalize">Scheduled</span>
                      </IonSegmentButton>
                    </span>
                    <span>
                      <IonSegmentButton
                        onClick={() => this.switchTasks("soon")}
                        className="shorterButton"
                        value="soon"
                      >
                        <span className="montX M capitalize">Soon</span>
                      </IonSegmentButton>
                    </span>
                    <span>
                      <IonSegmentButton
                        onClick={() => this.switchTasks("someday")}
                        className="shorterButton"
                        value="someday"
                      >
                        <span className="montX M capitalize">Someday</span>
                      </IonSegmentButton>
                    </span>
                  </IonSegment>
                </div>
                <hr />
                {this.loadingTasks ? (
                  <IonSpinner className="loading" />
                ) : (
                  <div className="flexBox">
                    {this.taskTimeLine === "thisWeek"
                      ? this.mapTasks(
                          [...this.scheduledTasks, ...this.completedTasks],
                          "thisWeek",
                          this.goals
                        )
                      : this.taskTimeLine === "soon"
                      ? this.mapTasks(
                          this.soonTasks,
                          this.taskTimeLine,
                          this.goals
                        )
                      : this.mapTasks(
                          this.somedayTasks,
                          this.taskTimeLine,
                          this.goals
                        )}
                  </div>
                )}
                <hr />
              </div>
            </div>

            <div className="dashboard-column">
              <div className="">
                <div className="dashboard-header">
                  <span className="mont SiteHeaderSM">ROUTINES</span>
                  {/* <IonButton
                  size="small"
                  color="primary"
                  onClick={() => this.newEmptyActivity("", "routine")}
                >
                  + Add
                </IonButton> */}
                </div>
                {this.loading ? (
                  <IonSpinner className="loading" />
                ) : (
                  <div className="flexBox" style={{ marginTop: "1.3rem" }}>
                    {this.mapRoutines(this.routines)}
                  </div>
                )}
                <hr />
              </div>
            </div>
          </div>
        </div>

        <IonModal
          cssClass={
            this.props.activityStore.createActivityType === "routine"
              ? "routine-modal"
              : "activity-modal"
          }
          isOpen={this.props.activityStore.createActivityModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            <ActivityForm
              closeNewActivityModal={this.closeNewActivityModal}
              changeTimeLine={this.changeTimeLine}
            />
          </div>
        </IonModal>

        <IonModal
          cssClass={
            this.props.activityStore.activeActivity.type === "routine"
              ? "routine-modal"
              : "activity-modal"
          }
          isOpen={this.props.activityStore.updateActivityModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            {/* <button
              className="x-button"
              onClick={() => this.closeActivityModal()}
            >
              x
            </button> */}
            <EditActivityForm closeActivityModal={this.closeActivityModal} />
          </div>
        </IonModal>
      </IonPage>
    );
  }
}

export default Dashboard;
