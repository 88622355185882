// Packages
import React from "react";
import { IonModal } from "@ionic/react";
import { autorun, action, computed, observable } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import _ from "lodash";
import moment from "moment";
// import { BulbOutlined, CheckOutlined, DeleteOutlined } from "@ant-design/icons";
// Components
import { Card, Alert, notification } from "antd";
import EditActivityForm from "../../../shared/forms/EditActivityForm/EditActivityForm";
import GoalReviewCard from "../GoalReviewCard/GoalReviewCard";
import ActivityCard from "../../../shared/cards/ActivityCard/ActivityCard";
import StepNavigation from "../StepNavigation/StepNavigation";
// Type Definitions
import { ICheckIn, IGoal, IActivity } from "../../../shared/interfaces";
import EditGoalForm from "../../../shared/forms/EditGoalForm/EditGoalForm";
import { track } from "../../../plugins/analytics";
interface IProps {
  checkInStore?: any;
  activityStore?: any;
  goalStore?: any;
  checkIn: ICheckIn;
  changeSkipToCheckIn: () => void;
}

@inject("checkInStore", "activityStore", "goalStore")
@observer
class StepOne extends React.Component<IProps> {
  @observable public loadingCount = 0;
  @observable public showSkeleton = true;

  // @observable goalsWorked = [];

  // @action async fetchGoalsWorked() {
  //   const goalIds = _.map(this.props.activityStore.activities, "goalId");
  //   await this.props.goalStore.getGoals("isActive=1");
  //   const goals = await this.props.goalStore.activeGoals.filter(
  //     (goal: IGoal) => {
  //       return goalIds.indexOf(goal.id) > -1 && goal.isActive === 1;
  //     }
  //   );
  //   this.loadingCount += 1;

  //   if (goals.length > 0) {
  //     this.showSkeleton = false;
  //     this.goalsWorked = goals;
  //   } else {
  //     this.goalsWorked = goals;
  //   }
  // }
  // componentDidMount() {
  //   this.fetchGoalsWorked();
  // }
  @computed get goalsWorked() {
    // const goalIds = _.map(this.props.activityStore.activities, "goalId");
    // const goals = this.props.goalStore.goals.filter((goal: IGoal) => {
    //   return goalIds.indexOf(goal.id) > -1 && goal.isActive && moment(goal.createdAt) <= moment(this.props.checkIn.endDate);
    // });
    // console.log("goals", goals);
    // if (goals.length > 0) {
    //   return goals;
    // } else {
    //   return goals;
    // }
    return this.props.checkInStore.activeCheckIn.data;
  }

  @computed get independentActivities() {
    return this.props.checkInStore.independentActivities;
    // return this.props.activityStore.activities.filter((activity: IActivity) => {
    //   return activity.goalId === "0";
    // });
  }

  // @action.bound
  // public getGoals() {
  //   this.props.goalStore.getGoals('isActive=1').then(() => {
  //     this.loadingCount += 1;
  //     this.showSkeleton = false;
  //   });
  // }

  // @action.bound
  // public getActivities() {
  //   const {
  //     activityStore,
  //     checkIn: { startDate, endDate },
  //   } = this.props;
  //   activityStore
  //     .getActivities("task", startDate, endDate, undefined, "scheduled")
  //     .then(() => {
  //       this.loadingCount += 1;
  //       this.props.activityStore.tasks = this.props.activityStore.tasks.filter(
  //         (task: IActivity) => {
  //           return task.status === "complete";
  //         }
  //       );
  //     });
  //   activityStore.getActivities("routine", startDate, endDate).then(() => {
  //     this.props.activityStore.routines =
  //       this.props.activityStore.routines.filter((routine: IActivity) => {
  //         // Filter for routines with historic entry for this check-in's week
  //         const dayOf = moment(this.props.checkIn.endDate).format("YYYY-MM-DD");
  //         const dayAfter = moment(this.props.checkIn.endDate)
  //           .add(1, "days")
  //           .format("YYYY-MM-DD");
  //         if (routine.history) {
  //           if (routine["history"][dayOf]) {
  //             if (routine["history"][dayOf].timesCompleted !== 0) {
  //               return routine["history"][dayOf];
  //             }
  //           } else if (routine["history"][dayAfter]) {
  //             if (routine["history"][dayAfter].timesCompleted !== 0) {
  //               return routine["history"][dayAfter];
  //             }
  //           }
  //         } else {
  //           return false;
  //         }
  //       });
  //     this.loadingCount += 1;
  //     if (
  //       this.props.activityStore.routines.length < 1 &&
  //       this.independentActivities.length < 1
  //     ) {
  //       this.incrementStep();
  //       this.props.changeSkipToCheckIn();
  //       notification.success({
  //         message: "No activities found for this week",
  //         description: "You can proceed to the next step to review your goals.",
  //       });
  //     }
  //   });
  // }

  @disposeOnUnmount
  public stepOneAutorun = autorun(() => {
    // this.getActivities();
    // this.getGoals();
    this.loadingCount = 4;
    this.showSkeleton = false;
  });

  @action.bound
  public closeGoalModal() {
    this.props.goalStore.updateGoalModal = false;
  }
  @action.bound
  incrementStep() {
    this.props.checkInStore.incrementStep();
  }

  public renderNullExplainer = () => {
    if (this.loadingCount > 2 && this.goalsWorked && this.goalsWorked.length < 1) {
      return (
        <div className="center-text">
          <Alert
            message="No goals found for this week. Proceed to the next step to review."
            type="info"
            showIcon
            className="inline-alert center"
          />
        </div>
      );
    }
  };

  public renderLoading = () => {
    if (this.loadingCount < 3 || this.showSkeleton) {
      return (
        <div className="check-in-card-wrapper">
          <Card className="goal-review-card" loading />
        </div>
      );
    }
  };

  public renderExtraActivities = () => {
    if (this.independentActivities.length > 0) {
      return (
        <div style={{ marginTop: "2rem" }}>
          <div className="center-text">
            <h3 className="step-title">
              And here is everything else you worked on
            </h3>
            <p className="step-description">
              Showing task & routines without goal assigned to them.
            </p>
          </div>

          <div className="check-in-card-wrapper" key="independent-activities">
            {this.independentActivities.map((activity: IActivity) => {
              return (
                <ActivityCard
                  key={activity.id}
                  activity={activity}
                  goalName=""
                  historicLookup={this.props.checkIn.endDate}
                />
              );
            })}
            <hr />
          </div>
        </div>
      );
    }
  };

  @action.bound
  public closeActivityModal() {
    this.props.activityStore.updateActivityModal = false;
  }

  render() {
    return (
      <div className="step-one">
        <div className="center-text">
          <h3 className="title-review">
            Here are the goals you worked on on this week
          </h3>
          <p className="subTitle-review">
            Take a minute to mark progress, add new actions, or complete goals
          </p>
        </div>
        {this.renderNullExplainer()}
        {this.renderLoading()}
        {this.goalsWorked &&
          this.showSkeleton === false &&
          this.goalsWorked.map((goal: IGoal) => {
            console.log(goal);
            return (
              <div className="check-in-card-wrapper" key={goal.id}>
                <GoalReviewCard
                  goal={goal}
                  loading={this.loadingCount < 3}
                  startDate={this.props.checkIn.startDate}
                  endDate={this.props.checkIn.endDate}
                />
              </div>
            );
          })}

        {this.renderExtraActivities()}

        <StepNavigation
          checkIn={this.props.checkIn}
          title="When you're ready, head over to next step"
          description="If you missed something, you can go always go back."
          allowIncrementStep
        />

        <IonModal
          cssClass={
            this.props.activityStore.createActivityType === "routine"
              ? "routine-modal"
              : "activity-modal"
          }
          isOpen={this.props.activityStore.updateActivityModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            {/* <button className="x-button" onClick={() => this.closeActivityModal()} >
              x
            </button> */}
            <EditActivityForm closeActivityModal={this.closeActivityModal} />
          </div>
        </IonModal>
        <IonModal
          cssClass={
            this.props.goalStore.activeGoal.status === "complete"
              ? "goal-modal-complete"
              : "goal-modal"
          }
          isOpen={this.props.goalStore.updateGoalModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            <EditGoalForm closeGoalModal={this.closeGoalModal} />
          </div>
        </IonModal>
      </div>
    );
  }
}

export default StepOne;
