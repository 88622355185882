// Packages
import React from "react";
import { action, computed } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";

import {
  BulbOutlined,
  CheckOutlined,
  DeleteOutlined,
  FlagFilled,
  LineChartOutlined,
  TrophyOutlined,
  UndoOutlined,
} from "@ant-design/icons";

// Components
import { Popconfirm, message, Card } from "antd";
import GoalActivities from "../../../shared/GoalActivities/GoalActivities";
// Styles
import "./GoalReviewCard.scss";
// Type Definitions
import { IActivity, IGoal } from "../../../shared/interfaces";
import FlagSVG from "../../../components/svgComponents/FlagSVG";
import { CompleteSVG } from "../../../components/svgComponents/CompleteSVG";
import { IonButton, IonIcon, IonList, IonPopover } from "@ionic/react";
import EditSVG from "../../../components/svgComponents/EditSVG";
import { ellipsisVertical } from "ionicons/icons";
import DeleteSVG from "../../../components/svgComponents/DeleteSVG";

interface IProps {
  goal: IGoal;
  loading: boolean;
  startDate: string;
  endDate: string;
  goalStore?: any;
  userStore?: any;
  activityStore?: any;
}

@inject("goalStore", "userStore", "activityStore")
@observer
class GoalCard extends React.Component<any> {
  @computed get inspirationUrl() {
    let url = "/inspiration";
    if (this.props.goal.templateId) {
      url += `?goalTemplateId=${this.props.goal.templateId}`;
    } else {
      url += "?onlyActivities=true";
    }
    return url;
  }

  @action.bound
  public completeGoal = () => {
    const goal = {
      ...this.props.goal,
      status: "complete",
      completedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.goalStore.updateGoal(goal, 1).then((err: string) => {
      if (!err) {
        message.success("Goal completed!! Congrats!");
        this.props.userStore.showCelebration = true;
        setTimeout(() => {
          this.props.userStore.showCelebration = false;
        }, 3000); // Match antd message timing
      }
    });
  };

  @action.bound
  public decompleteGoal = () => {
    const goal = {
      ...this.props.goal,
      status: "incomplete",
    };

    this.props.goalStore.updateGoal(goal, 1).then((err: string) => {
      if (!err) {
        message.info("Goal reset to incomplete");
      }
    });
  };

  @action.bound
  public async resetGoalActivities() {
    const key = "deleteGoalMessage";
    message.loading({ content: "Removing goal...", key });
    const resetActivitiesError =
      await this.props.activityStore.resetGoalActivities(this.props.goal.id);
    await this.props.activityStore._promisedTimeout(1000); // Ensure message readability
    if (resetActivitiesError) {
      message.error({
        content: "Error removing goal. Please try again later.",
        key,
        duration: 2,
      });
    } else {
      this.archiveGoal(key);
    }
  }

  public goToGoalDetails() {
    this.props.history.push({ pathname: `/goals/${this.props.goal.id}` });
  }

  @action.bound
  public async archiveGoal(key: string) {
    message.loading({ content: "Deleting goal...", key });
    const deleteGoalError = await this.props.goalStore.updateGoal(
      this.props.goal,
      0
    );
    await this.props.activityStore._promisedTimeout(1000); // Ensure message readability
    if (!deleteGoalError) {
      message.success({ content: "Goal deleted", key, duration: 2 });
    } else {
      message.error({
        content: "Error deleting goal. Please try again later.",
        key,
        duration: 2,
      });
    }
  }

  @action.bound
  public archiveCompleteGoal = () => {
    this.props.goalStore.updateGoal(this.props.goal, 0);
    message.success("Goal deleted");
  };

  @action.bound
  public renderCardStyles() {
    let styles = "goal-review-card";
    if (this.props.goal.status === "complete")
      styles += " complete-goal-review-card";
    return styles;
  }

  @action.bound
  public editGoal = () => {
    this.props.goalStore.activeGoal = this.props.goal;
    this.props.goalStore.updateGoalModal = true;
  };

  @action.bound
  public renderActions() {
    // Set universal actions
    let actions = [
      <Popconfirm
        title="Are you sure? Tasks and routines will not be deleted."
        onConfirm={
          this.props.goal.status === "complete"
            ? this.archiveCompleteGoal
            : this.resetGoalActivities
        }
        okText="Delete goal"
        cancelText="Nevermind"
      >
        <DeleteOutlined
          key="delete"
          title="This is no longer important to me."
        />
      </Popconfirm>,

      <Link to={this.inspirationUrl} target="_blank">
        <BulbOutlined
          key="bulb"
          title="I want to work on this next week. Show me how!"
        />
      </Link>,
    ];

    // Set dynamic actions based on completion status
    if (this.props.goal.status === "complete") {
      actions.push(
        <Popconfirm
          title="Want to keep working on this goal?"
          onConfirm={this.decompleteGoal}
          okText="Mark incomplete"
          cancelText="Nevermind"
        >
          <UndoOutlined title="Mark incomplete" />
        </Popconfirm>
      );
    } else {
      actions.push(
        <Popconfirm
          title="Take a second to review - is this goal complete?"
          onConfirm={this.completeGoal}
          okText="Complete goal"
          cancelText="Nevermind"
        >
          <CheckOutlined key="check" title="I have achieved this goal!" />
        </Popconfirm>
      );
    }

    return actions;
  }

  render() {
    if (this.props.goal.isActive === 0) {
      return null;
    }

    return (
      <div className="review-card-container">
        <div className="flexBoxRow">
          <h3 className="review-goal-card-title">
            <FlagSVG color="#FF7E1D" /> {this.props.goal.name}
          </h3>
          <div className="kebabMenu">
            <KebabMenu
              editGoal={this.editGoal}
              resetGoalActivities={this.resetGoalActivities}
              goToGoalDetails={this.goToGoalDetails}
              completeGoal={this.completeGoal}
              goal={this.props.goal}
              activityStore={this.props.activityStore}
            />
          </div>
        </div>
        <GoalActivities
          goal={this.props.goal}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
        />
      </div>
    );
  }
}

export default withRouter(GoalCard);

interface KebabMenuProps {
  editGoal: () => void;
  resetGoalActivities: () => void;
  goToGoalDetails: () => void;
  completeGoal: () => void;
  goal: IGoal;
  activityStore: any;
}

interface KebabMenuState {
  showPopover: boolean;
  event?: Event;
}

class KebabMenu extends React.Component<KebabMenuProps, KebabMenuState> {
  constructor(props: KebabMenuProps) {
    super(props);
    this.state = {
      showPopover: false,
    };
  }

  public hasIncompletedActivities() {
    const { id } = this.props.goal;
    const { tasks, routines } = this.props.activityStore;
    const hasIncompletedTasks = tasks.some(
      (task: IActivity) => task.goalId === id
    );
    const hasIncompletedRoutines = routines.some(
      (routine: IActivity) => routine.goalId === id
    );
    return hasIncompletedRoutines || hasIncompletedTasks;
  }

  handlePopoverOpen = (e: React.MouseEvent) => {
    this.setState({ showPopover: true, event: e.nativeEvent });
  };

  handlePopoverClose = () => {
    this.setState({ showPopover: false });
  };

  handleEditGoalClick = () => {
    this.props.editGoal();
    this.setState({ showPopover: false });
  };

  handleResetGoalActivitiesClick = () => {
    this.props.resetGoalActivities();
    this.setState({ showPopover: false });
  };

  render() {
    return (
      <>
        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.event}
          onDidDismiss={this.handlePopoverClose}
        >
          <IonList className="small-width">
            <IonButton
              onClick={() => this.handleEditGoalClick()}
              expand="full"
              className="no-background"
            >
              <div className="edit">
                <EditSVG /> Edit
              </div>
            </IonButton>
            {this.hasIncompletedActivities() ? (
              <Popconfirm
                title="You have uncompleted tasks and/or routines for this goal. Please take a second to either delete or complete them"
                onConfirm={() => this.props.goToGoalDetails()}
                okText="Okay"
                cancelText="Cancel"
              >
                <IonButton expand="full" className="no-background">
                  <div className="edit">
                    <div className="flexBoxRow">
                      <CompleteSVG /> Complete
                    </div>
                  </div>
                </IonButton>
              </Popconfirm>
            ) : (
              <IonButton
                expand="full"
                className="no-background"
                onClick={() =>
                  this.hasIncompletedActivities()
                    ? null
                    : this.props.completeGoal()
                }
              >
                <div className="edit">
                  <div className="flexBoxRow">
                    <CompleteSVG /> Complete
                  </div>
                </div>
              </IonButton>
            )}
            <Popconfirm
              title="Are you sure you want to delete this goal?"
              onConfirm={this.handleResetGoalActivitiesClick}
              okText="Delete goal"
              cancelText="Keep at it"
            >
              <IonButton expand="full" className="no-background">
                <div className="delete">
                  <DeleteSVG />
                  Delete
                </div>
              </IonButton>
            </Popconfirm>
          </IonList>
        </IonPopover>
        <IonButton
          className="menu-button"
          onClick={(e) => this.handlePopoverOpen(e)}
        >
          <IonIcon icon={ellipsisVertical} />
        </IonButton>
      </>
    );
  }
}

// <Card
//   className={this.renderCardStyles()}
//   loading={this.props.loading}
//   title={
//     <>
//       <FlagSVG color="#FF7E1D" />
//       &nbsp;
//       <span>{this.props.goal.name}</span>
//     </>
//   }
//   actions={this.renderActions()}
//   >
//   </Card>
