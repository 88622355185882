// Packages
import React from "react";
import { IonPage, IonModal, IonSpinner } from "@ionic/react";
import { observable, autorun, action } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
// Components
import GoalCard from "../../shared/cards/GoalCard/GoalCard";
import GoalCompleteCard from "../../shared/cards/GoalCompleteCard/GoalCompleteCard";
import GoalForm from "../../shared/forms/GoalForm/GoalForm";
import EditGoalForm from "../../shared/forms/EditGoalForm/EditGoalForm";
import { RightOutlined } from "@ant-design/icons";
import { Collapse, Empty, notification } from "antd";
import Masonry from "react-masonry-css";
// Styles
import "./Goals.scss";
// Type Definitions
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IGoal } from "../../shared/interfaces";
import FlagSVG from "../../components/svgComponents/FlagSVG";
import { postData } from "../../plugins/auth/auth";

interface IProps extends RouteComponentProps {
  goalStore: any;
  userStore: any;
}

const { Panel } = Collapse;

const customPanelStyle = {
  borderRadius: 4,
  border: 0,
  overflow: "hidden",
};

const breakpointColsGoals = {
  default: 3,
  1550: 3,
  1370: 2,
  650: 1,
};

@inject("goalStore", "userStore")
@observer
class Goals extends React.Component<IProps> {
  @observable
  public loading = false;
  @observable public userPreFilledInfo: any = "";
  @observable public urlParams: any = "";
  @observable public baseInit: any = {
    headers: {},
    response: true,
  };
  public componentWillMount() {
    this.props.userStore.fetchAssociation();
    this.urlParams = new URLSearchParams(window.location.search);
    if (this.urlParams.get("invite") === null) {
      return;
    }
    const invitationId = JSON.parse(atob(this.urlParams.get("invite")));
    if (!invitationId) {
      return;
    }
    postData(
      "invitation",
      `/invitation/${invitationId.invitationId}`,
      this.baseInit
    ).then((res) => {
      if (res.status === 200) {
        notification.success({
          message: "Invitation accepted!",
          description: "You have successfully accepted the invitation.",
        });
        document.cookie =
          "invitationId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
      window.location.href = "/goals";
    });
  }

  @disposeOnUnmount
  public goalsAutorun = autorun(() => {
    this.props.goalStore.updateGoalModal = false;
    this.props.goalStore.createGoalModal = false;
    this.getActiveGoals();
    this.getPriorityGoals();
    this.getInactiveGoals();
    this.getCompleteGoals();
  });

  @action.bound
  public getActiveGoals = () => {
    if (this.props.goalStore.activeGoals.length < 1) this.loading = true;
    this.props.goalStore
      .getGoals("isActive=1&isPriority=0")
      .then((goals: IGoal[]) => {
        this.loading = false;
      });
  };

  @action.bound
  public getPriorityGoals = () => {
    if (this.props.goalStore.priorityGoals.length < 1) this.loading = true;
    this.props.goalStore.getGoals("isPriority=1").then((goals: IGoal[]) => {
      this.loading = false;
    });
  };

  @action.bound
  public getInactiveGoals = () => {
    if (this.props.goalStore.goals.length < 1) this.loading = true;
    this.props.goalStore.getGoals("isActive=0").then((goals: IGoal[]) => {
      this.loading = false;
    });
  };

  @action.bound
  public getCompleteGoals = () => {
    if (this.props.goalStore.goals.length < 1) this.loading = true;
    this.props.goalStore.getGoals("status=complete").then((goals: IGoal[]) => {
      this.loading = false;
    });
  };

  @action.bound
  public newEmptyGoal = () => {
    this.props.goalStore.activeGoalTemplate = {};
    this.props.goalStore.createGoalModal = true;
  };

  public renderActiveGoals = () => {
    console.log(this.props.goalStore.activeGoals, "activeGoals");
    if (!this.loading && this.props.goalStore.activeGoals.length < 1) {
      return (
        <Empty
          description={
            <>
              <span>No active goals found.</span>
              <br />
              <span> Hit the '+' button above to get started!</span>
            </>
          }
        />
      );
    } else if (this.props.goalStore.activeGoals.length >= 1) {
      /* handle Masonry bug with only two cards present */
      return (
        <div className="cards-list">
          {this.props.goalStore.activeGoals.map((goal: IGoal) => {
            return (
              <div key={goal.id}>
                <GoalCard classNameForWrapper="give-padding" goal={goal} />
              </div>
            );
          })}
        </div>
      );
    }
  };

  public renderPriorityGoals = () => {
    if (this.props.goalStore.priorityGoals.length < 1) {
      return (
        <Empty
          description={
            <>
              <span>No priority goals found.</span>
            </>
          }
        />
      );
    } else if (this.props.goalStore.priorityGoals.length >= 1) {
      return (
        <div className="cards-list">
          {this.props.goalStore.priorityGoals.map((goal: IGoal) => {
            return (
              <div key={goal.id}>
                <GoalCard classNameForWrapper="give-padding" goal={goal} />
              </div>
            );
          })}
        </div>
      );
    }
    //  else {
    //   return (
    //     <div className="cards-list">
    //       <Masonry
    //         breakpointCols={breakpointColsGoals}
    //         className="masonry-grid"
    //         columnClassName="masonry-grid-column"
    //       >
    //         {this.props.goalStore.priorityGoals.map((goal: IGoal) => {
    //           return (
    //             <div key={goal.id}>
    //               <GoalCard classNameForWrapper="faux-class" goal={goal} />
    //             </div>
    //           );
    //         })}
    //       </Masonry>
    //     </div>
    //   );
    // }
  };

  public renderInActiveGoals = () => {
    if (this.props.goalStore.inActiveGoals.length < 1) {
      return <Empty description={<span>None yet - keep at it!</span>} />;
    } else if (this.props.goalStore.inActiveGoals.length >= 1) {
      return (
        <div className="cards-list">
          {this.props.goalStore.inActiveGoals.map((goal: IGoal) => {
            return (
              <div key={goal.id} className="in-active-goals">
                <GoalCard classNameForWrapper="faux-class" goal={goal} />
              </div>
            );
          })}
        </div>
      );
    }
  };

  public renderCompleteGoals = () => {
    if (this.props.goalStore.completedGoals.length < 1) {
      return <Empty description={<span>None yet - keep at it!</span>} />;
    } else if (this.props.goalStore.completedGoals.length >= 1) {
      return (
        <div className="cards-container">
          {this.props.goalStore.completedGoals.map((goal: IGoal) => {
            return (
              <div key={goal.id}>
                <GoalCompleteCard
                  classNameForWrapper="give-padding"
                  goal={goal}
                />
              </div>
            );
          })}
        </div>
      );
    }
  };

  @action.bound
  public closeNewGoalModal() {
    this.props.goalStore.createGoalModal = false;
  }

  @action.bound
  public closeGoalModal() {
    this.props.goalStore.updateGoalModal = false;
  }

  render() {
    return (
      <IonPage className="scroll-page goals-page">
        <div className="main-content-goals ion-padding">
          <div className="header-goals">
            <h1 className="SiteHeader">
              <div className="IconWrapper">
                <FlagSVG className="active" />
              </div>
              Goals
            </h1>
            <button
              className="create-goal-button"
              onClick={() => this.newEmptyGoal()}
            >
              <div className="plus-sign"></div>
            </button>
          </div>

          {this.loading ? (
            <IonSpinner className="loading" />
          ) : (
            <Collapse
              bordered={false}
              defaultActiveKey={["0", "1"]}
              expandIcon={({ isActive }) => (
                <RightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel
                header={<span className="mont M">Priority</span>}
                key="0"
                style={customPanelStyle}
              >
                {this.renderPriorityGoals()}
              </Panel>
              <Panel
                header={<span className="mont M">Active</span>}
                key="1"
                style={customPanelStyle}
              >
                {this.renderActiveGoals()}
              </Panel>

              <Panel
                header={<span className="mont M">InActive</span>}
                key="2"
                style={customPanelStyle}
              >
                {this.renderInActiveGoals()}
              </Panel>
              <Panel
                header={<span className="mont M">Completed</span>}
                key="3"
                style={customPanelStyle}
              >
                {this.renderCompleteGoals()}
              </Panel>
            </Collapse>
          )}
          <IonModal
            cssClass={"new-goal-modal"}
            isOpen={this.props.goalStore.createGoalModal}
            border-radius="7"
            backdropDismiss={false}
          >
            <div className="ion-padding modal-content overflow-y-scroll">
              <GoalForm closeNewGoalModal={this.closeNewGoalModal} />
            </div>
          </IonModal>

          <IonModal
            cssClass={
              this.props.goalStore.activeGoal.status === "complete"
                ? "goal-modal-complete"
                : "goal-modal"
            }
            isOpen={this.props.goalStore.updateGoalModal}
            border-radius="7"
            backdropDismiss={false}
          >
            <div className="ion-padding modal-content overflow-y-scroll">
              <EditGoalForm closeGoalModal={this.closeGoalModal} />
            </div>
          </IonModal>
        </div>
      </IonPage>
    );
  }
}

export default withRouter(Goals);
